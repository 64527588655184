import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const deviceApi = {
  getByUsername: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices/owned`,
          method: "GET",
        }),
      redirect
    );
  },
  get: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices`,
          method: "GET",
        }),
      redirect
    );
  },
  getWithState: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices/state`,
          method: "GET",
        }),
      redirect
    );
  },
  getWithStatuses: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices/statuses`,
          method: "GET",
        }),
      redirect
    );
  },
  getById: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices/${id}`,
          method: "GET",
        }),
      redirect
    );
  },
  create: async function (device, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices`,
          method: "POST",
          data: device,
        }),
      redirect
    );
  },
  update: async function (device, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices`,
          method: "PUT",
          data: device,
        }),
      redirect
    );
  },
  delete: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices/${id}`,
          method: "DELETE",
        }),
      redirect
    );
  },
  takeDeviceAwayFromUser: async function (deviceId, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices/takeaway/${deviceId}`,
          method: "PATCH",
        }),
      redirect
    );
  },
  getUsersInfo: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/devices/usersInfo`,
          method: "GET",
        }),
      redirect
    );
  },
};
