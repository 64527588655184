export const documentTitleDictionary = {
    mainTitle: "ІХП",
    deviceListTitle: "Пристрої",
    devicesTitle: "Перелік пристроїв",
    loginTitle: "Авторизація",
    schedulingTitle: "Дизайнер сценаріїв",
    editScheduleTitle: "Редагувати сценарій",
    editDeviceTitle: "Редагувати пристрій",
    devicesManagementTitle: "Управління пристроями",
    certificagesManagementTitle: "Управління сертифікатами",
    devicesMessagesManagementTitle: "Управління повідомленнями для пристрою",
    usersMessagesManagementTitle: "Управління повідомленнями для користувача",
    usersManagementTitle: "Управління користувачами",
    firmwareManagementTitle: "Управління прошивками"
}
