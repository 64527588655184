import React from "react";
import { errorHandlingDictionary } from "../../dictionary/ua/errorHandlingDictionary";

const NotFoundPage = () => {
    
  return (
    <div>
        <h2>{errorHandlingDictionary.pageNotFound}</h2>
    </div>
  );
}

export default NotFoundPage;
