export const firmwareManagementDictionary = {
    noFirmwareFound: "Не знайдено прошивок",
    fileLinkHeaderText: "Посилання",
    firmwareVersionHeaderText: "Версія прошивки",
    hardwareVersionHeaderText: "Версія апаратного забезпечення",
    clientIdHeaderText: "Id девайсу",
    espIdfHeaderText: "Середовище розробки",
    targetHeaderText: "Назва контролера",
    prjNameHeaderText: "Ім'я проекту",
    compileDateHeaderText: "Дата створення прошивки",
    ownerUsernameHeaderText: "Власник",
    descriptionHeaderText: "Опис",
    deviceProfileHeaderText: "Профіль пристрою",
    createDateTimeHeaderText: "Дата створення запису",
    addFirmwareButtonLabel: "Додати прошивку",
    firmwareAlreadyExistsErrorText: "Прошивка з такими версіями вже існує"
}