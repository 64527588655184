import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const keyCloakApi = {
  getUsers: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/keycloak/users`,
          method: "GET",
        }),
      redirect
    );
  },
};
