import { devicesManagementDictionary } from "../../../../dictionary/ua/devicesManagementDictionary";
import { Link } from "react-router-dom";
import { links } from "../../../../enums/links";

const CertificatesManagementButtonWrapper = ({
  deviceId
}) => {
  return (
    <Link
      key="edit"
      to={links.getCertificatesManagementByDeviceEditLink(deviceId)}
    >
      {devicesManagementDictionary.certificatesManagementButtonLabel}
    </Link>
  );
};

export default CertificatesManagementButtonWrapper;
