import { useEffect, useState } from "react";
import classes from "../../../css/pages/admin/user-messages-management/userMessagesManagement.module.css";
import userMessageClasses from "../../../css/pages/admin/user-messages-management/userMessageHeader.module.css";
import { documentTitleDictionary } from "../../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import useSetCurrentUserResult from "../../../hooks/useSetCurrentUserResult";
import { links } from "../../../../enums/links";
import { Link, useParams } from "react-router-dom";
import ReturnButton from "../../../assets/buttons/ReturnButton";
import UserMessageRow from "./UserMessageRow";
import { userMessagesApi } from "../../../../api/userMessagesApi";
import { userMessagesDictionary } from "../../../../dictionary/ua/userMessagesDictionary";
import SortableHeader from "../../../assets/SortableHeader";
import { usersManagementDictionary } from "../../../../dictionary/ua/usersManagementDictionary";
import { useUserMessages } from "../../../context/UserMessagesContext";

const UserMessagesManagement = () => {
  useDocumentTitle(documentTitleDictionary.usersMessagesManagementTitle + " - " + documentTitleDictionary.mainTitle);
  const { username } = useParams();
  const currentUserResult = useSetCurrentUserResult();
  const { userMessagesList, setUserMessagesList } = useUserMessages();
  const [filteredUserMessagesList, setFilteredUserMessagesList] = useState([]);
  const userMessageManagementFields = [
    { key: 'message', label: usersManagementDictionary.messageLabel },
    { key: 'createdDateTimeUtc', label: usersManagementDictionary.createdDateLabel },
  ];

  const fetchUserMessagesByUsername = async (username) => {
    try {
      const response = await userMessagesApi.getByUsername(
        username,
        links.getUserMessagesManagementByUsernameEditLink(username)
      );
      
      if (response.data?.length > 0) {
        setUserMessagesList(response.data);
        setFilteredUserMessagesList(response.data);
      }
    } catch (error) {
      console.error("Error fetching user messages list:", error);
    }
  };

  useEffect(() => {
    const fetchUserMessages = async () => {
      await fetchUserMessagesByUsername(username);
    };

    fetchUserMessages();
  }, []);
  
  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        <div>
          <Link
            className={classes["return-to-device-management-link"]}
            to={links.usersManagementPath}
          >
            <ReturnButton>
              {userMessagesDictionary.returnToUsersManagementText}
            </ReturnButton>
          </Link>
        </div>
        {userMessagesList.length > 0 ? (
          <>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["user-message-header"]}>
                <SortableHeader
                  fields={userMessageManagementFields}
                  list={userMessagesList}
                  setFilteredList={setFilteredUserMessagesList}
                  classes={userMessageClasses}
                />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["user-message-row"]}>
                <div className={classes["rows-container"]}>
                  {filteredUserMessagesList.map((userMessage) => (
                    <UserMessageRow
                      key={userMessage.id}
                      userMessage={userMessage}
                      setUserMessagesList={setUserMessagesList}
                    />
                  ))}
                </div>
              </div>
              <div className={classes["aside-for-body"]}></div>
            </div>
          </>
        ) : (
          <h2>{userMessagesDictionary.noAvailableUserMessagesText}</h2>
        )}
      </div>
    )
  );
};

export default UserMessagesManagement;
