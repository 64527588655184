import classes from "../../../css/deviceStatus.module.css";
import convertVoltageLevelToPercentage from "../../../../helpers/batteryVoltageLevelConverter";
import { deviceStatusType } from "../../../../enums/deviceStatus";

const BatteryVoltageLevelStatus = ({ device }) => {
  return (
      <div className={classes["device-status-wrapper"]}>
        <div>
          {device.batteryVoltageLevel ? (
            <>
              {convertVoltageLevelToPercentage(
                device?.batteryVoltageLevel
              )}
              %
            </>
          ) : (
            <>{deviceStatusType.notApplicable}</>
          )}
        </div>
      </div>
  );
};

export default BatteryVoltageLevelStatus;
