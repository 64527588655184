import { addDeviceDictionary } from "../dictionary/ua/addDeviceDictionary";

export const deviceProfile = {
  imitator: "imitator",
  remoteSwitch: "remoteSwitch",
  gsmGate: "gsmGate",
};

export const deviceProfileMapper = {
  imitator: addDeviceDictionary.imitatorProfileText,
  remoteSwitch: addDeviceDictionary.remoteSwitchProfileText,
  gsmGate: addDeviceDictionary.gsmGateProfileText,
};
