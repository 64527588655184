export const waitDurationOption = {
    forNSeconds: "for-n-seconds",
    untilTimestamp: "until-timestamp",
    untilDatetime: "until-datetime",
    untilIncomingCallFromPhoneNumber: "until-incoming-call-from-phoneNumber",
    untilAnyIncomingCall: "until-any-incoming-call",
    fromNtoMSeconds: "from-n-to-m-seconds"
};

export const waitDurationOptionLabels = {
    "for-n-seconds": (seconds) => `W=${seconds};`,
    "until-timestamp": (timestamp) => `U=${timestamp};`,
    "until-datetime": (datetime) => `U=${datetime};`,
    "until-incoming-call-from-phoneNumber": (phoneNumber) => `U=RING${phoneNumber};`,
    "until-any-incoming-call": () => "U=RING*;",
    "from-n-to-m-seconds": (fromSeconds, toSeconds) => `W=${fromSeconds}-${toSeconds};`
};


