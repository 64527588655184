import React, { createContext, useState, useContext } from 'react';

const UserMessagesContext = createContext();

export const UserMessagesProvider = ({ children }) => {
  const [userMessagesList, setUserMessagesList] = useState([]);

  return (
    <UserMessagesContext.Provider value={{ userMessagesList, setUserMessagesList }}>
      {children}
    </UserMessagesContext.Provider>
  );
};

export const useUserMessages = () => useContext(UserMessagesContext);
