import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { certificatesApi } from "../../../../api/certificatesApi";
import { useState } from "react";
import { certificatesManagementDictionary } from "../../../../dictionary/ua/certificatesManagementDictionary";
import { links } from "../../../../enums/links";

const RegenerateCrlButtonWrapper = ({ deviceId }) => {
  const [pressed, setPressed] = useState(false);
  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };
  
  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };
  
  
  const handleClick = async () => {
    await certificatesApi.generateCrl(links.getCertificatesManagementByDeviceEditLink(deviceId));
  };

  return (
    <Button
      className={`${buttonClasses["regenerate-crl-button"]} ${
        buttonClasses[setPressedClass()]
      }`}
      startIcon="start"
      onClick={() => handleClick()}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {certificatesManagementDictionary.regenerateCrlButtonLabel}
    </Button>
  );
};

export default RegenerateCrlButtonWrapper;
