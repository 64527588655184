import React, { useState } from "react";
import classes from "../../../css/pages/admin/user-management/addUserMessage.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addNewUserMessageSchema } from "../../../../validation/validationSchemas";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import Button from "../../../assets/buttons/Button";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import { links } from "../../../../enums/links";
import { userMessagesApi } from "../../../../api/userMessagesApi";
import { userMessagesDictionary } from "../../../../dictionary/ua/userMessagesDictionary";
import { usersManagementDictionary } from "../../../../dictionary/ua/usersManagementDictionary";

function AddUserMessage({
  usersList,
  setUsersList
}) {
  const [pressed, setPressed] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(addNewUserMessageSchema()),
    mode: "onChange",
    context: { usersList },
  });

  const [disabled, setDisabled] = useState(true);

  const getSelectAllCheckboxIndicator = () => {
    const selectedCount = usersList.filter((item) => item.selected).length;

    return selectedCount === usersList.length;
  };

  const getSelectAnyCheckboxIndicator = () => {
    const selectedCount = usersList.filter((item) => item.selected).length;

    return selectedCount > 0;
  };

  const onSubmit = async (data) => {
    let payload = {
      usernames: usersList.filter((u) => u.selected).map((u) => u.username),
      message: data.message,
      messageType: data.messageType,
    };

    setDisabled(true);
    userMessagesApi
      .createMany(payload, links.userMessagesManagementLink)
      .then((response) => {
        reset({
          message: "",
        });
      });
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const isDirtyAndValid = () => {
    const messageFieldState = getFieldState("message");
    if (messageFieldState.isDirty && !messageFieldState.invalid) {
      return true;
    }

    return false;
  };

  const handleChange = () => {
    if(isDirtyAndValid()) {
      setDisabled(false);
    }
    else {
      setDisabled(true);
    }
  };

  const handleSelectAllClick = () => {
    setUsersList((prevState) => {
      const updatedUsersList = prevState.map((user) => ({
        ...user,
        selected: !selectAll,
      }));
      
      return updatedUsersList;
    });

    setSelectAll(!selectAll);
  };

  return (
    <div className={classes["add-user-message-wrapper"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("message").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="message">
                {userMessagesDictionary.messageInputLabel}
              </label>
            </span>
            <TextField
              id="message"
              type="text"
              name="message"
              size="small"
              multiline
              rows={3}
              {...register("message")}
              variant="outlined"
            />
            <FormHelperText>{errors?.message?.message}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <Button
            className={`${buttonClasses["add-user-message-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            icon={<PlusIcon />}
            startIcon="start"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {userMessagesDictionary.addUserMessageButtonText}
          </Button>
        </div>
        <div className={classes["select-all-users-checkbox-wrapper"]}>
          <label htmlFor="select-all-users-checkbox">
            {usersManagementDictionary.selectAllUsersLabel}
          </label>
          <input
            id="select-all-checkbox"
            type="checkbox"
            className={classes["select-all-users-checkbox"]}
            onChange={handleSelectAllClick}
            checked={getSelectAllCheckboxIndicator()}
          />
        </div>
      </form>
    </div>
  );
}

export default AddUserMessage;
