export const usersManagementDictionary = {
    usernameHeaderText: "Ім'я користувача",
    userMessagesManagementButtonLabel: "Управління повідомленнями для користувача",
    noUsersWithDevices: "Немає користувачів з наявними пристроями",
    messageLabel: "Повідомлення",
    createdDateLabel: "Дата створення",
    selectAllUsersLabel: "Вибрати всіх користувачів",
    descriptionHeaderText: "Опис",
    isActiveHeaderText: "Активність",
    yesText: "Так",
    noText: "Ні",
    deviceProfilesText: "Профілі пристроїв",
    applyFilters: "Застосувати"
}