import { useState } from "react";
import { dateTimeHelper } from "../../../../helpers/dateTimeHelper";
import classes from "../../../css/pages/admin/firmware-management/firmwareRow.module.css";
import { links } from "../../../../enums/links";
import DeleteButton from "../../../assets/buttons/DeleteButton";
import DeleteItemsDialog from "../../../assets/dialogs/DeleteItemsDialog";
import UList from "../../../assets/lists/UList";
import { firmwareApi } from "../../../../api/firmwareApi";
import { deleteFirmwareDialogDictionary } from "../../../../dictionary/ua/deleteFirmwareDialogDictionary";

const FirmwareRow = ({ firmware, setFirmwareList }) => {
  const [open, setOpen] = useState(false);
  const handleOpenDeleteUserMessageDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteUserMessageDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      firmwareApi.delete(firmware.id, links.firmwareManagementLink).then((response) => {
        if (response.success === true) {
          setFirmwareList((prevState) => {
            return prevState.filter((um) => firmware.id !== um.id);
          });
        }
      });
    }
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div className={`${classes["row"]}`}>
          <div className={classes["hardwareVersion-column"]}>
            {firmware.hardwareVersion}
          </div>
          <div className={classes["firmwareVersion-column"]}>
            {firmware.firmwareVersion}
          </div>
          <div className={classes["clientId-column"]}>{firmware.clientId}</div>
          <div className={classes["espIdf-column"]}>{firmware.espIdf}</div>
          <div className={classes["target-column"]}>{firmware.target}</div>
          <div className={classes["prjName-column"]}>{firmware.prjName}</div>
          <div className={classes["compileDate-column"]}>
            {dateTimeHelper.getLocalDateTime(firmware.compileDate, {
              hour: "2-digit",
              minute: "2-digit",
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </div>
          <div className={classes["fileLink-column"]}>{firmware.fileLink}</div>
          <div className={classes["description-column"]}>
            {firmware.description}
          </div>
          <div className={classes["deviceProfile-column"]}>
            {firmware.deviceProfile}
          </div>
          <div className={classes["ownerUsername-column"]}>
            {firmware.ownerUsername}
          </div>
          <div className={classes["createdDatetime-column"]}>
            {dateTimeHelper.getLocalDateTime(firmware.createdDateTimeUtc)}
          </div>
          <div className={classes["delete-firmware-button-column"]}>
            <DeleteButton onClick={handleOpenDeleteUserMessageDialog} />
            <DeleteItemsDialog
              open={open}
              onClose={handleCloseDeleteUserMessageDialog}
              items={[firmware.fileLink]}
              deleteItemDialogDictionary={deleteFirmwareDialogDictionary}
              UList={UList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmwareRow;