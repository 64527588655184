const SortingIcon = () => {
  return (
    <svg
      width="10"
      height="24"
      viewBox="0 0 12 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.60036 9.5L11.4007 9.5C11.51 9.49966 11.6172 9.46945 11.7107 9.41262C11.8042 9.35579 11.8804 9.2745 11.9312 9.1775C11.982 9.08049 12.0054 8.97144 11.9989 8.86209C11.9925 8.75274 11.9564 8.64724 11.8945 8.55692L6.49433 0.742984C6.27052 0.419007 5.73171 0.419007 5.5073 0.742984L0.107146 8.55692C0.044639 8.64704 0.00798352 8.75261 0.00116193 8.86214C-0.00565966 8.97167 0.0176136 9.08098 0.068453 9.17819C0.119292 9.2754 0.195754 9.3568 0.289529 9.41353C0.383305 9.47027 0.490809 9.50017 0.60036 9.5ZM6.00051 2.14108L10.2558 8.29786L1.74519 8.29786L6.00051 2.14108Z"
        fill="#343434"
      />
      <path
        d="M11.3996 16.5L0.599331 16.5C0.489984 16.5003 0.3828 16.5306 0.289317 16.5874C0.195834 16.6442 0.119593 16.7255 0.0687992 16.8225C0.0180056 16.9195 -0.00541666 17.0286 0.00105308 17.1379C0.00752282 17.2473 0.0436404 17.3528 0.105518 17.4431L5.50567 25.257C5.72948 25.581 6.26829 25.581 6.4927 25.257L11.8929 17.4431C11.9554 17.353 11.992 17.2474 11.9988 17.1379C12.0057 17.0283 11.9824 16.919 11.9315 16.8218C11.8807 16.7246 11.8042 16.6432 11.7105 16.5865C11.6167 16.5297 11.5092 16.4998 11.3996 16.5ZM5.99949 23.8589L1.74416 17.7021L10.2548 17.7021L5.99949 23.8589Z"
        fill="#343434"
      />
    </svg>
  );
};

export default SortingIcon;
