import classes from "../../css/assets/buttons/button.module.css";

const Button = ({
  id,
  className,
  disabled,
  type,
  icon,
  startIcon,
  children,
  onClick,
  onMouseDown,
  onMouseUp
}) => {
  return (
    <button
      id={id}
      className={className}
      disabled={disabled}
      type={type}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <span className={classes[startIcon]}>{icon}</span>
      <span>{children}</span>
    </button>
  );
};

export default Button;
