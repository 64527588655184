import config from "../config";

export default function convertVoltageLevelToPercentage(currentVoltageLevel) {
    const lowVoltageLevel = config.converters.batteryVoltageLevelConverter.lowVoltageLevel;
    const highVoltageLevel = config.converters.batteryVoltageLevelConverter.highVoltageLevel;
    if(currentVoltageLevel > highVoltageLevel) {
        return 100;
    }

    if(currentVoltageLevel < lowVoltageLevel) {
        return 0;
    }
    
    const difference = highVoltageLevel - lowVoltageLevel;
    const newScaleMaximum = difference;
    const newScaleCurrentLevel = Math.abs(newScaleMaximum - (highVoltageLevel - currentVoltageLevel));
    const newScaleCurrentLevelPercentage = Math.round((newScaleCurrentLevel / newScaleMaximum) * 100);
    return newScaleCurrentLevelPercentage;
}