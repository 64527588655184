export const scheduleDesigner = {
    radioSilenceForNMinutesButtonText: "Радіотиша впродовж n хвилин",
    radioSilenceUntilDatetimeButtonText: "Радіотиша до конкретної дати",    
    radioSilenceUntilTimestampButtonText: "Радіотиша до конкретної години",
    waitForNSecondsButtonText: "Чекати впродовж n секунд",
    waitUntilDatetimeButtonText: "Чекати до конкретної дати",
    waitUntilTimestampButtonText: "Чекати до конкретної години",
    waitUntilIncomingCallFromPhoneNumberButtonText: "Чекати до дзвінка з конкретного номера",
    waitUntilAnyIncomingCallButtonText: "Чекати до будь-якого дзвінка",
    waitFromNtoMSecondsButtonText: "Чекати від n до m секунд",
    dialFromNGsmToPhoneNumberButtonText: "Подзвонити з n GSM-модуля на номер",
    hangUpActiveCallForNGsmButtonText: "Завершити активний дзвінок для n GSM-модуля",
    setGsmModeButtonText: "Встановити режим GSM",
    setMp3FileNumberForGsmPlaybackButtonText: "Встановити mp3-файл для програвання через GSM",
    setRadioModeButtonText: "Встановити режим Радіо",
    setMp3FileNumberForRadioPlaybackButtonText: "Встановити mp3-файл для програвання через рацію",
    setRadioChannelForFirstTransceiverButtonText: "Встановити радіо-канал для 1го трансивера",
    setRadioChannelForSecondTransceiverButtonText: "Встановити радіо-канал для 2го трансивера",
    setRadioChannelForBothTransceiverButtonText: "Встановити радіо-канал для двох трансиверів",
    playButtonText: "Включити програвання",
    playGSMMonologueFileButtonText: "Програвання GSM monologue",
    playRadioDMRFileButtonText: "Програвання Radio DMR",
    onceButtonText: "Один раз",
    waitForNSecondsInformationText: "час очікування в секундах",
    waitFromNtoMsecondsInformationText: "час очікування від n до m секунд",
    repeatNTimesButtonText: "Повторити n разів",
    nSecondsLabelText: "n секунд",
    mSecondsLabelText: "m секунд",
    dateTimeLabelText: "Дата очікування",
    phoneNumberSampleLabelText: "+380-00-000-00-00",
    radioModeLabelText: "Режим радіо",
    setRadioModeInformationText: "Виберіть режим радіо. Для опції 'Mp3-програвач' потрібно ще вибрати аудіо трек",
    trackNumberLabelText: "Номер треку",
    radioChannelLabelText: "Радіо-канал",
    numberOfRepetitionsLabelText: "n повторень",
    repeatNtimesInformationText: "0 - нескінченне повторення, 1 - раз повторити (відтворення сценарію двічі), для відсутності повторень не задавайте даний атрибут",
    dialFromNGsmToPhoneNumberInformationText: "Введіть номер на який подзвонити та номер gsm-модуля з якого дзвонити",
    hangupActiveCallForGsmNumberInformationText: "Виберіть gsm-модуль для завершення дзвінка",
    setGsmModeInformationText: "Виберіть режим GSM"
}