import classes from "../../../css/deviceStatus.module.css";
import RelayPicker from "../../../assets/relay/RelayPicker";

const RelayStatus = ({ device }) => {
  return (
    <div className={classes["device-status-wrapper"]}>
      <div>
        <RelayPicker relayMode={device.relay} />
      </div>
    </div>
  );
};

export default RelayStatus;
