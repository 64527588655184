import classes from "../../../css/pages/scheduling/commands/waitFromNtoMSeconds.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { waitDurationOption, waitDurationOptionLabels } from "../../../../enums/scheduling/waitDurationOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { waitFromN_to_M_secondsSchema } from "../../../../validation/validationSchemas";

const WaitFromN_to_M_seconds = ({ insertAtCursor }) => {
  const [seconds, setSeconds] = useState({ fromSeconds: "", toSeconds: "" });
  
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(waitFromN_to_M_secondsSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("fromSeconds").invalid && getFieldState("toSeconds").invalid;
  }
  
  const onSubmit = () => {
    insertAtCursor(waitDurationOptionLabels[waitDurationOption.fromNtoMSeconds](seconds?.fromSeconds, seconds?.toSeconds));
  };
 
  const handleChange = (event) => {
    setSeconds((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value
        };
      });
  };

  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button className={`${buttonClasses["wait-from-n-to-m-seconds-button"]}`}>
          {scheduleDesigner.waitFromNtoMSecondsButtonText}
        </Button>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <InputLabel htmlFor="fromSeconds">{scheduleDesigner.nSecondsLabelText}</InputLabel>
            <OutlinedInput
              type="text"
              size="small"
              value={seconds?.fromSeconds}
              name="fromSeconds"
              {...register("fromSeconds")}
            />
             <FormHelperText>{errors?.fromSeconds?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <InputLabel htmlFor="toSeconds">{scheduleDesigner.mSecondsLabelText}</InputLabel>
            <OutlinedInput
              type="text"
              size="small"
              value={seconds?.toSeconds}
              name="toSeconds"
              {...register("toSeconds")}
            />
             <FormHelperText>{errors?.toSeconds?.message}</FormHelperText>
             
             <label htmlFor="seconds">{scheduleDesigner.waitFromNtoMsecondsInformationText}</label>
          </FormControl>
        </div>
        <div className={classes["information"]}>
          {scheduleDesigner.repeatNTimesButtonTooltipText}
        </div>
      </form>
    </div>
  );
};

export default WaitFromN_to_M_seconds;