import classes from "../../../css/pages/admin/certificates-management/certificateHeader.module.css";
import Button from "../../../assets/buttons/Button";
import SortingIcon from "../../../assets/icons/SortingIcon";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import { useState } from "react";
import { certificatesManagementDictionary } from "../../../../dictionary/ua/certificatesManagementDictionary";

const CertificateHeader = () => {
  const [active, setActive] = useState(null);
  const [pressed, setPressed] = useState(null);
  const setActivityStatus = (id) => {
    return active === id ? "active" : "";
  };
  const setPressedStatus = (id) => {
    return pressed === id ? "pressed" : "";
  };

  const handleClick = (event) => {
    // TODO: implement sorting
    setActive(event.currentTarget.id);
  };

  const handleMouseDown = (event) => {
    setPressed(event.currentTarget.id);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(null);
  };

  return (
    <>
      <div className={classes["header"]}>
        <div className={classes["commonName-header-column"]}>
          <Button
            id="commonNameButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["commonName-header-button"]
            } ${buttonClasses[setActivityStatus("commonNameButton")]} ${
              buttonClasses[setPressedStatus("commonNameButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {certificatesManagementDictionary.commonNameHeaderText}
          </Button>
        </div>
        <div className={classes["serialNumber-header-column"]}>
          <Button
            id="serialNumberButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["serialNumber-header-button"]
            } ${buttonClasses[setActivityStatus("serialNumberButton")]} ${
              buttonClasses[setPressedStatus("serialNumberButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {certificatesManagementDictionary.serialNumberHeaderText}
          </Button>
        </div>
        <div className={classes["createdDate-header-column"]}>
          <Button
            id="createdDateButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["createdDate-header-button"]
            } ${buttonClasses[setActivityStatus("createdDateButton")]} ${
              buttonClasses[setPressedStatus("createdDateButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {certificatesManagementDictionary.createdDateTimeUtcHeaderText}
          </Button>
        </div>

        <div className={classes["expirationDate-header-column"]}>
          <Button
            id="expirationDateButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["expirationDate-header-button"]
            } ${buttonClasses[setActivityStatus("expirationDateButton")]} ${
              buttonClasses[setPressedStatus("expirationDateButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {certificatesManagementDictionary.expirationDateTimeUtcHeaderText}
          </Button>
        </div>
        <div className={classes["revoked-header-column"]}>
          <Button
            id="revokedButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["revoked-header-button"]
            } ${buttonClasses[setActivityStatus("revokedButton")]} ${
              buttonClasses[setPressedStatus("revokedButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {certificatesManagementDictionary.revokedHeaderText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CertificateHeader;
