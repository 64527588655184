import classes from "../../../css/assets/icons/gsmSignalLevel/noSignalGsmSignalLevel.module.css";

const NoSignalGsmSignalLevel = () => {
  return (
    <div className={classes.wrapper}>
      <svg
        width="21"
        height="12"
        viewBox="0 0 21 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.84314"
          y="7.65686"
          width="2"
          height="4"
          rx="1"
          fill="#343434"
        />
        <rect
          x="4.84314"
          y="4.65686"
          width="2"
          height="7"
          rx="1"
          fill="#343434"
        />
        <rect
          x="18.7426"
          y="0.34314"
          width="2"
          height="14"
          rx="1"
          transform="rotate(45 18.7426 0.34314)"
          fill="#343434"
        />
        <rect
          x="8.84315"
          y="1.75732"
          width="2"
          height="14"
          rx="1"
          transform="rotate(-45 8.84315 1.75732)"
          fill="#343434"
        />
      </svg>
    </div>
  );
};

export default NoSignalGsmSignalLevel;
