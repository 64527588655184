import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const mqttApi = {
  sendMessage: async function (data, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/mqtt/message`,
          method: "POST",
          data: data
        }),
      redirect
    );
  },
};
