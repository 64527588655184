import { useEffect, useState } from "react";
import classes from "../../css/pages/user-messages/userMessages.module.css";
import userMessageClasses from "../../css/pages/user-messages/userMessageHeader.module.css";
import { userMessagesDictionary } from "../../../dictionary/ua/userMessagesDictionary";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import useSetCurrentUserResult from "../../hooks/useSetCurrentUserResult";
import UserMessageRow from "./UserMessageRow";
import { userMessagesApi } from "../../../api/userMessagesApi";
import { links } from "../../../enums/links";
import SortableHeader from "../../assets/SortableHeader";

const UserMessages = () => {
  useDocumentTitle(documentTitleDictionary.usersMessagesManagementTitle + " - " + documentTitleDictionary.mainTitle);
  const currentUserResult = useSetCurrentUserResult();
  const [userMessagesList, setUserMessagesList] = useState([]);
  const [filteredUserMessagesList, setFilteredUserMessagesList] = useState([]);
  const userMessagesFields = [
    { key: 'message', label: userMessagesDictionary.messageLabel },
    { key: 'createdDateTimeUtc', label: userMessagesDictionary.createdDateLabel },
  ];

  const fetchUserMessages = async () => {
    try {
      const response = await userMessagesApi.getForUser(links.userMessagesLink);
      if (response.data?.length > 0) {
        setUserMessagesList(response.data);
        setFilteredUserMessagesList(response.data);
      }
    } catch (error) {
      console.error("Error fetching user messages list:", error);
    }
  };

  useEffect(() => {
    const fetchUserMessagesAsync = async () => {
      await fetchUserMessages();
    };

    fetchUserMessagesAsync();
  }, []);
  
  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        {userMessagesList.length > 0 ? (
          <>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["user-message-header"]}>
                <SortableHeader
                  fields={userMessagesFields}
                  list={userMessagesList}
                  setFilteredList={setFilteredUserMessagesList}
                  classes={userMessageClasses}
                />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["user-message-row"]}>
                <div className={classes["rows-container"]}>
                  {filteredUserMessagesList.map((userMessage) => (
                    <UserMessageRow
                      key={userMessage.id}
                      userMessage={userMessage}
                      setUserMessagesList={setUserMessagesList}
                    />
                  ))}
                </div>
              </div>
              <div className={classes["aside-for-body"]}></div>
            </div>
          </>
        ) : (
          <h2>{userMessagesDictionary.noAvailableUserMessagesText}</h2>
        )}
      </div>
    )
  );
};

export default UserMessages;
