import React, { useState } from "react";
import classes from "../../../css/pages/admin/device-messages-management/addDeviceMessage.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addNewDeviceMessageSchema } from "../../../../validation/validationSchemas";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import Button from "../../../assets/buttons/Button";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import { links } from "../../../../enums/links";
import { deviceMessagesDictionary } from "../../../../dictionary/ua/deviceMessagesDictionary";
import { deviceMessagesApi } from "../../../../api/deviceMessagesApi";
import { deviceMessageCategory, deviceMessageTypeMapper } from "../../../../mappers/deviceMessageMapper";

function AddDeviceMessage({ setDeviceMessagesList, deviceId }) {
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(addNewDeviceMessageSchema),
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    let payload = {
      deviceId: deviceId,
      message: data.message
    };

    setDisabled(true);
    deviceMessagesApi.create(payload, links.deviceMessagesManagementLink).then((response) => {
      if (response.success === true) {
        reset({
          message: ""
        });

        setDeviceMessagesList((prevState) => {
          payload.messageType = deviceMessageTypeMapper.mapDeviceMessageType(deviceMessageCategory.MessageFromAdmin)
          return [...prevState, response.data];
        });
      }
    });
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const isDirtyAndValid = () => {
    if (
      getFieldState("message").isDirty &&
      !getFieldState("message").invalid
    ) {
      return true;
    }

    return false;
  };

  const handleChange = () => {
    if (isDirtyAndValid()) {
      setDisabled(false);
    }
  };

  return (
    <div className={classes["add-device-message-wrapper"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("message").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="message">
                {deviceMessagesDictionary.messageInputLabel}
              </label>
            </span>
            <TextField
              id="message"
              type="text"
              name="message"
              size="small"
              multiline
              rows={3}
              {...register("message")}
              variant="outlined"
            />
            <FormHelperText>{errors?.message?.message}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <Button
            className={`${buttonClasses["add-device-message-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            icon={<PlusIcon />}
            startIcon="start"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {deviceMessagesDictionary.addDeviceMessageButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddDeviceMessage;
