import { useState } from "react";
import { dateTimeHelper } from "../../../../helpers/dateTimeHelper";
import classes from "../../../css/pages/admin/user-messages-management/userMessageRow.module.css";
import { links } from "../../../../enums/links";
import DeleteButton from "../../../assets/buttons/DeleteButton";
import DeleteItemsDialog from "../../../assets/dialogs/DeleteItemsDialog";
import UList from "../../../assets/lists/UList";
import { userMessagesApi } from "../../../../api/userMessagesApi";
import { deleteUserMessageDialogDictionary } from "../../../../dictionary/ua/deleteUserMessageDialogDictionary";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const UserMessageRow = ({ userMessage, setUserMessagesList }) => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(userMessage.message);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditChange = (e) => {
    setEditedMessage(e.target.value);
  };

  const handleEditSubmit = async () => {
    try {
      const updatedMessage = {
        ...userMessage,
        message: editedMessage,
      };

      const response = await userMessagesApi.update(updatedMessage, links.getUserMessagesManagementByUsernameEditLink(updatedMessage.username));
      if (response.success === true) {
        setUserMessagesList((prevState) => prevState.map((um) => (um.id === userMessage.id ? updatedMessage : um)));
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating user message:", error);
    }
  };

  const handleOpenDeleteUserMessageDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteUserMessageDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      userMessagesApi.delete(userMessage.id, links.getUserMessagesManagementByUsernameEditLink(userMessage.username)).then((response) => {
        if (response.success === true) {
          setUserMessagesList((prevState) => {
            return prevState.filter((um) => userMessage.id !== um.id);
          });
        }
      });
    }
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div className={`${classes["row"]}`}>
          <div className={classes["message-column"]}>
            {isEditing ? (
              <input
                type="text"
                value={editedMessage}
                onChange={handleEditChange}
                onBlur={handleEditSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleEditSubmit();
                  if (e.key === "Escape") setIsEditing(false);
                }}
                autoFocus
              />
            ) : (
              <>
                {userMessage.message}
                <IconButton>
                  <EditIcon onClick={handleEditClick} />
                </IconButton>
              </>
            )}
          </div>
          <div className={classes["createdDate-column"]}>
            {dateTimeHelper.getLocalDateTime(userMessage.createdDateTimeUtc)}
          </div>
          <div className={classes["delete-user-message-button-column"]}>
            <DeleteButton onClick={handleOpenDeleteUserMessageDialog} />
            <DeleteItemsDialog
              open={open}
              onClose={handleCloseDeleteUserMessageDialog}
              items={[userMessage.message]}
              deleteItemDialogDictionary={deleteUserMessageDialogDictionary}
              UList={UList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessageRow;