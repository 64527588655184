export const schedulingDictionary = {
    scheduleLabelText: "Поле сценарію",
    deleteLatestCommandButtonText: "Видалити останню команду зі сценарію",    
    clearScheduleFieldButtonText: "Очистити поле сценарію",
    ownSchedulesLabelText: "Власні сценарії",
    publicSchedulesLabelText: "Публічні сценарії",
    publicScheduleTooltipText: "Публічно доступний",
    privateScheduleTooltipText: "Приватний",
    selectScheduleTooltipText: "Вибрати",
    deleteScheduleTooltipText: "Видалити",
    editScheduleTooltipText: "Редагувати",
    copyScheduleTooltipText: "Копіювати до власних"
}