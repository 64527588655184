import classes from "../../css/assets/buttons/returnButton.module.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ReturnButton = ({children, startIcon}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes[startIcon]}>
        <ArrowBackIcon />
      </span>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default ReturnButton;
