import classes from "../../../css/pages/scheduling/commands/setRadioMode.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";
import { setRadioModeOption, setRadioModeOptionLabels } from "../../../../enums/scheduling/setRadioModeOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setRadioModeSchema } from "../../../../validation/validationSchemas";
import { mp3PlaybackSelect } from "../../../../enums/mp3PlaybackSelect";
import { mp3PlaybackDictionary } from "../../../../dictionary/ua/scheduling/mp3PlaybackDictionary";
import { radioModeSelect } from "../../../../enums/scheduling/radioModeSelect";

const SetRadioMode = ({ insertAtCursor  }) => {
  const [radioMode, setRadioMode] = useState("");
  const [mp3Playback, setMp3Playback] = useState("");
  const [trackNumber, setTrackNumber] = useState("");
  const [result, setResult] = useState("");
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(setRadioModeSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("radioMode").invalid || getFieldState("mp3Playback").invalid || getFieldState("trackNumber").invalid;
  }

  const onSubmit = () => {
    insertAtCursor(setRadioModeOptionLabels[setRadioModeOption.setRadioMode](result.replace("x", "")));
  };

  const handleChange = (event) => {
    if (event.target.value !== radioModeSelect.mp3Playback.value) {
      setMp3Playback("");
    }
    
    setRadioMode(event.target.value)
    setResult(event.target.value);
  };

  const handleMp3PlaybackChange = (event) => {    
    if (event.target.value !== mp3PlaybackSelect.trackNumber.value) {
      setTrackNumber("");
      setResult(`${radioMode}${event.target.value}`);
    }
    else {
      setResult(`${radioMode}`);
    }
    
    setMp3Playback(event.target.value);
  };

  const handleTrackNumberChange = (event) => {
    setTrackNumber(event.target.value);
    if(mp3Playback !== mp3PlaybackSelect.trackNumber.value) {
      setResult(`${radioMode}${mp3Playback}`);
    }
    else {
      setResult(`${radioMode}${event.target.value}`);
    }
  };

  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button className={`${buttonClasses["set-radio-mode-button"]}`}>
          {scheduleDesigner.setRadioModeButtonText}
        </Button>
        <div className={classes["radio-mode-container"]}>
          <FormControl className={classes["form-control"]} error={isError()}>
            <Select
              className={classes["radio-mode-select"]}
              value={radioMode}
              size="small"
              {...register("radioMode")}
              onChange={handleChange}
            >
              <MenuItem value={radioModeSelect.disconnected.value}>{radioModeSelect.disconnected.text}</MenuItem>
              <MenuItem value={radioModeSelect.speaker.value}>{radioModeSelect.speaker.text}</MenuItem>
              <MenuItem value={radioModeSelect.mp3Playback.value}>{radioModeSelect.mp3Playback.text}</MenuItem>
            </Select>
            <FormHelperText>{errors?.radioMode?.message}</FormHelperText>
          </FormControl>
          
          {radioMode === radioModeSelect.mp3Playback.value && (
            <FormControl className={classes["form-control"]} error={isError()}>
              <Select
                className={classes["mp3-playback-select"]}
                value={mp3Playback}
                size="small"
                {...register("mp3Playback")}
                onChange={handleMp3PlaybackChange}
              >
                <MenuItem value={mp3PlaybackSelect.nextTrack.value}>{mp3PlaybackDictionary.nextTrack}</MenuItem>
                <MenuItem value={mp3PlaybackSelect.randomTrack.value}>{mp3PlaybackDictionary.randomTrack}</MenuItem>
                <MenuItem value={mp3PlaybackSelect.trackNumber.value}>{mp3PlaybackDictionary.trackNumber}</MenuItem>
              </Select>
              <FormHelperText>{errors?.mp3Playback?.message}</FormHelperText>
            </FormControl>
          )}

          {mp3Playback === mp3PlaybackSelect.trackNumber.value && (
            <FormControl
              className={classes["form-control"]}
              error={isError()}
              onChange={handleTrackNumberChange}
            >
              <OutlinedInput
                type="text"
                size="small"
                value={trackNumber}
                name="trackNumber"
                {...register("trackNumber")}
              />
              <FormHelperText>{errors?.trackNumber?.message}</FormHelperText>
              <InputLabel htmlFor="trackNumber">{scheduleDesigner.trackNumberLabelText}</InputLabel>
            </FormControl>
          )}
           <label>{scheduleDesigner.setRadioModeInformationText}</label>
        </div>
        <div className={classes["information"]}>
          {scheduleDesigner.repeatNTimesButtonTooltipText}
        </div>
      </form>
    </div>
  );
};

export default SetRadioMode;
