import { addDeviceDictionary } from "../../dictionary/ua/addDeviceDictionary";
import { usersManagementDictionary } from "../../dictionary/ua/usersManagementDictionary";
import { deviceProfile } from "../../enums/deviceProfile";

const usersManagementDictionaryMapping = {
  "deviceProfiles": usersManagementDictionary.deviceProfilesText,
  "applyFilters": usersManagementDictionary.applyFilters,
  [deviceProfile.imitator]: addDeviceDictionary.imitatorProfileText,
  [deviceProfile.remoteSwitch]: addDeviceDictionary.remoteSwitchProfileText,
  [deviceProfile.gsmGate]: addDeviceDictionary.gsmGateProfileText,
};

const usersManagementDictionaryMapper = {
  map: (key) => { return usersManagementDictionaryMapping[key] || null },
};

export default usersManagementDictionaryMapper;
