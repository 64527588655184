import classes from "../../../css/pages/admin/device-messages-management/deviceMessageHeader.module.css";
import Button from "../../../assets/buttons/Button";
import SortingIcon from "../../../assets/icons/SortingIcon";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import { useState } from "react";
import { deviceMessagesDictionary } from "../../../../dictionary/ua/deviceMessagesDictionary";

const DeviceMessageHeader = () => {
  const [active, setActive] = useState(null);
  const [pressed, setPressed] = useState(null);
  const setActivityStatus = (id) => {
    return active === id ? "active" : "";
  };
  const setPressedStatus = (id) => {
    return pressed === id ? "pressed" : "";
  };

  const handleClick = (event) => {
    // TODO: implement sorting
    setActive(event.currentTarget.id);
  };

  const handleMouseDown = (event) => {
    setPressed(event.currentTarget.id);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(null);
  };

  return (
    <>
      <div className={classes["header"]}>
        <div className={classes["message-header-column"]}>
          <Button
            id="messageButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["messageButton-header-button"]
            } ${buttonClasses[setActivityStatus("messageButton")]} ${
              buttonClasses[setPressedStatus("messageButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {deviceMessagesDictionary.messageHeaderText}
          </Button>
        </div>
        <div className={classes["messageType-header-column"]}>
          <Button
            id="messageType"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["messageType-header-button"]
            } ${buttonClasses[setActivityStatus("messageType")]} ${
              buttonClasses[setPressedStatus("messageType")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {deviceMessagesDictionary.messageTypeHeaderText}
          </Button>
        </div>
        <div className={classes["createdDate-header-column"]}>
          <Button
            id="createdDateButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["createdDate-header-button"]
            } ${buttonClasses[setActivityStatus("createdDateButton")]} ${
              buttonClasses[setPressedStatus("createdDateButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {deviceMessagesDictionary.createdDateTimeUtcHeaderText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default DeviceMessageHeader;
