import classes from "../../css/assets/icons/clockIcon.module.css";

const ClockIcon = () => {
  return (
    <span className={classes["clock-icon-wrapper"]}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 10C0.5 4.477 4.977 0 10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20C4.977 20 0.5 15.523 0.5 10ZM10.5 2C8.37827 2 6.34344 2.84285 4.84315 4.34315C3.34285 5.84344 2.5 7.87827 2.5 10C2.5 12.1217 3.34285 14.1566 4.84315 15.6569C6.34344 17.1571 8.37827 18 10.5 18C12.6217 18 14.6566 17.1571 16.1569 15.6569C17.6571 14.1566 18.5 12.1217 18.5 10C18.5 7.87827 17.6571 5.84344 16.1569 4.34315C14.6566 2.84285 12.6217 2 10.5 2Z"
          fill="#343434"
        />
        <rect
          x="16.5"
          y="9"
          width="2"
          height="7"
          rx="1"
          transform="rotate(90 16.5 9)"
          fill="#343434"
        />
        <rect x="9.5" y="3" width="2" height="8" rx="1" fill="#343434" />
      </svg>
    </span>
  );
};

export default ClockIcon;
