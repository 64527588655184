import { useEffect, useState } from "react"
import { deviceApi } from "../../../api/deviceApi";
import { devicesDictionary } from "../../../dictionary/ua/devicesDictionary";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import EnhancedTable from "./assets/EnhancedTable";
import { devicesManagementDictionary } from "../../../dictionary/ua/devicesManagementDictionary";
import useSetCurrentUserResult from "../../hooks/useSetCurrentUserResult";
import deviceMapper from "../../../mappers/deviceMapper";
import TakeDeviceAwayButtonWrapper from "./assets/TakeDeviceAwayButtonWrapper";
import CertificatesManagementButtonWrapper from "./assets/CertificatesManagementButtonWrapper";
import classes from "../../css/pages/admin/devicesManagement.module.css";
import { links } from "../../../enums/links";
import { columnType } from "../../../enums/columnType";
import DeviceMessagesManagementButtonWrapper from "./assets/DeviceMessagesManagementButtonWrapper";

const DevicesManagement = () => {
  useDocumentTitle(documentTitleDictionary.devicesManagementTitle + " - " + documentTitleDictionary.mainTitle);
  const currentUserResult = useSetCurrentUserResult();
  const [deviceList, setDeviceList] = useState([]);

  const fetchDeviceList = async () => {
    try {
      const response = await deviceApi.getWithState(links.devicesManagementLink);
      if (response.data?.length > 0) {
        const mappedDevices = response.data.map((device) => deviceMapper.mapToDeletableObject(device));
        setDeviceList(mappedDevices);
      }
    } catch (error) {
      console.error("Error fetching device list:", error);
    }
  };

  useEffect(() => {
    const fetchDevices = async () => {
      await fetchDeviceList();
    };
  
    fetchDevices();
  }, [currentUserResult]);
  
  const columnsStructure = [
    { id: "clientId", rowLabel: devicesManagementDictionary.clientId, headerLabel:  devicesManagementDictionary.clientId},
    { id: "name", rowLabel: devicesManagementDictionary.name, headerLabel: devicesManagementDictionary.name, },
    { id: "ownerUsername", rowLabel: devicesManagementDictionary.ownerUsername, headerLabel: devicesManagementDictionary.ownerUsername },
    { id: "profile", rowLabel: devicesManagementDictionary.profile, headerLabel: devicesManagementDictionary.profile },
    { id: "createdDateTimeUtc", rowLabel: devicesManagementDictionary.createdDateTimeUtc, headerLabel: devicesManagementDictionary.createdDateTimeUtc, columnType: columnType.dateTime },
    {
      id: "takeDeviceAwayButton",
      headerLabel: "",
      rowLabel: devicesManagementDictionary.takeDeviceAwayButtonLabel,
      component: TakeDeviceAwayButtonWrapper,
      componentProps: {
        setDeviceList: setDeviceList,
        currentUsername: currentUserResult.currentUsername
      }
    },
    {
      id: "certificatesManagementButton",
      headerLabel: "",
      rowLabel: devicesManagementDictionary.certificatesManagementButtonLabel,
      component: CertificatesManagementButtonWrapper
    },
    {
      id: "deviceMessagesManagementButton",
      headerLabel: "",
      rowLabel: devicesManagementDictionary.deviceMessagesManagementButtonLabel,
      component: DeviceMessagesManagementButtonWrapper
    }
  ];

  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        {deviceList.length > 0 ? (
          <EnhancedTable data={deviceList} columns={columnsStructure} />
        ) : (
          <h2>{devicesDictionary.noAvailableDeviceText}</h2>
        )}
      </div>
    )
  );
};

export default DevicesManagement;
