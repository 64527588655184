import { scheduleResponseDictionary } from "../dictionary/ua/scheduling/scheduleResponseDictionary";

export const scheduleResponseMapper = (scheduleResponse) => {
  for (const response of scheduleResponses) {
    if (scheduleResponse?.includes(response.key)) {
      return response.value;
    }
  }

  return null;
};

const scheduleResponses = [
  { key: "SCENARIOACCEPTED", value: scheduleResponseDictionary.scenarioAccepted },
  { key: "INVALIDSCENARIO", value: scheduleResponseDictionary.invalidScenario },
  { key: "END", value: scheduleResponseDictionary.scenarioFinished }
];
