import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ErrorIcon from '@mui/icons-material/Error';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import classes from "../components/css/assets/icons/iconManager.module.css";

export const iconManager = {
    getIconByType: (type) => {
        switch (type) {
          case 'warning':
            return <WarningIcon className={classes["warning-icon"]} />;
          case 'error':
            return <DangerousIcon className={classes["error-icon"]} />;
          case 'information':
            return <ErrorIcon className={classes["information-icon"]} />;
          case 'admin':
            return <AccountCircleIcon className={classes["admin-icon"]} />;
          default:
            return null;
        }
    }
}