import { useState } from "react";
import { dateTimeHelper } from "../../../../helpers/dateTimeHelper";
import classes from "../../../css/pages/admin/device-messages-management/deviceMessageRow.module.css";
import { deviceMessagesApi } from "../../../../api/deviceMessagesApi";
import { links } from "../../../../enums/links";
import { deviceMessageTypeMapper } from "../../../../mappers/deviceMessageMapper";
import DeleteButton from "../../../assets/buttons/DeleteButton";
import DeleteItemsDialog from "../../../assets/dialogs/DeleteItemsDialog";
import UList from "../../../assets/lists/UList";
import { deleteDeviceMessageDialogDictionary } from "../../../../dictionary/ua/deleteDeviceMessageDialogDictionary";


const DeviceMessageRow = ({ deviceMessage, setDeviceMessagesList }) => {
  const [open, setOpen] = useState(false);
  const handleOpenDeleteDeviceMessageDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteDeviceDeviceDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      deviceMessagesApi.delete(deviceMessage.id, links.getDeviceMessagesManagementByDeviceEditLink(deviceMessage.deviceId)).then((response) => {
        if (response.success === true) {
          setDeviceMessagesList((prevState) => {
            return prevState.filter((dm) => deviceMessage.id !== dm.id);
          });
        }
      });
    }
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div className={`${classes["row"]}`}>
          <div className={classes["message-column"]}>
            {deviceMessage.message}
          </div>
          <div className={classes["messageType-column"]}>
            {deviceMessageTypeMapper.mapDeviceMessageType(deviceMessage.messageType)}
          </div>
          <div className={classes["createdDate-column"]}>
            {dateTimeHelper.getLocalDateTime(deviceMessage.createdDateTimeUtc)}
          </div>
          <div className={classes["delete-device-message-button-column"]}>
            <DeleteButton onClick={handleOpenDeleteDeviceMessageDialog} />
            <DeleteItemsDialog
              open={open}
              onClose={handleCloseDeleteDeviceDeviceDialog}
              items={[deviceMessage.message]}
              deleteItemDialogDictionary={deleteDeviceMessageDialogDictionary}
              UList={UList}
            />
          </div> 
        </div>
      </div>
    </div>
  );
};

export default DeviceMessageRow;