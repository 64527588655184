import { useRef, useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
    document.title = title;
  }, [title]);
}

export default useDocumentTitle;
