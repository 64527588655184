export const deviceMessagesDictionary = {
    deviceGoesOfflineMessageText: "Включення приладу",
    deviceGoesOnlineMessageText: "Виключення приладу",
    lowBatteryVoltageLevelMessageText: "Низький заряд батареї",
    lowGsmBalanceMessageText: "Брак коштів на рахунку",
    scheduleFinishedMessageText: "Відпрацювання циклу сценарію завершено",
    showDeviceMessageCheckboxText: "Показати повідомлення для пристрою",
    createdDateTimeUtcHeaderText: "Дата створення",
    messageHeaderText: "Повідомлення",
    messageTypeHeaderText: "Тип повідомлення",
    noAvailableDeviceMessagesText: "Немає повідомлень для пристрою",
    messageInputLabel: "Повідомлення",
    addDeviceMessageButtonText: "Додати",
    lowGsmBalanceText: "lowGsmBalance",
    lowBatteryVoltageLevelText: "lowBatteryVoltageLevel",
    scheduleFinishedText: "scheduleFinished",
    deviceGoesOnlineText: "deviceGoesOnline",
    deviceGoesOfflineText: "deviceGoesOffline",
    messageFromAdmin: "Повідомлення від адміністратора",
    messageFromSystem: "Системне повідомлення",    
    returnToDevicesManagementText: "Повернутись до сторінки управління пристроями",
}