import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const certificatesApi = {
  generate: async function (payload, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/certificates/generate`,
          method: "POST",
          data: payload,
        }),
      redirect
    );
  },
  revoke: async function (payload, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/certificates/revoke`,
          method: "PATCH",
          data: payload,
        }),
      redirect
    );
  },
  undoRevocation: async function (payload, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/certificates/undoRevocation`,
          method: "PATCH",
          data: payload,
        }),
      redirect
    );
  },
  download: async function (serialNumber, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/certificates/download/${serialNumber}`,
          method: "GET",
          responseType: "blob",
        }),
      redirect
    );
  },
  generateCrl: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/certificates/generateCrl`,
          method: "POST",
        }),
      redirect
    );
  },
  getByDeviceId: async function (deviceId, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/certificates/management/device/${deviceId}`,
          method: "GET",
        }),
      redirect
    );
  },
  delete: async function (serialNumber, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/certificates/${serialNumber}`,
          method: "DELETE",
        }),
        redirect
    );
  },
};
