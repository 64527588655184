import classes from "../../../css/assets/dropdowns/profiles/gsmGate.module.css";

const GsmGateProfile = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default GsmGateProfile;
