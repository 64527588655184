import { useEffect, useState } from "react";
import classes from "../../../css/pages/admin/user-management/userManagement.module.css";
import userHeaderClasses from "../../../css/pages/admin/user-management/userHeader.module.css";
import { documentTitleDictionary } from "../../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import useSetCurrentUserResult from "../../../hooks/useSetCurrentUserResult";
import { links } from "../../../../enums/links";
import UserRow from "./UserRow";
import { deviceApi } from "../../../../api/deviceApi";
import { usersManagementDictionary } from "../../../../dictionary/ua/usersManagementDictionary";
import SortableHeader from "../../../assets/SortableHeader";
import AddUserMessage from "./AddUserMessage";
import { useUserMessages } from "../../../context/UserMessagesContext";
import { keyCloakApi } from "../../../../api/keyCloakApi";
import { dateTimeHelper } from "../../../../helpers/dateTimeHelper";
import { deviceProfile } from "../../../../enums/deviceProfile";
import usersManagementDictionaryMapper from "../../../dictionaryMapper/usersManagementDictionaryMapper";

const UsersManagement = () => {
  useDocumentTitle(documentTitleDictionary.usersManagementTitle + " - " + documentTitleDictionary.mainTitle);
  const currentUserResult = useSetCurrentUserResult();
  const [usersList, setUsersList] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const { userMessagesList, setUserMessagesList } = useUserMessages();
  const filters = {
    deviceProfiles: [
      deviceProfile.imitator,
      deviceProfile.remoteSwitch,
      deviceProfile.gsmGate,
    ],
  };

  const userFields = [
    { key: 'username', label: usersManagementDictionary.usernameHeaderText },
    { key: 'description', label: usersManagementDictionary.descriptionHeaderText },
    { key: 'createdDateTimeUtc', label: usersManagementDictionary.createdDateLabel },
    { key: 'isActive', label: usersManagementDictionary.isActiveHeaderText },
    { key: 'deviceProfiles', label: usersManagementDictionary.deviceProfilesText }
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await keyCloakApi.getUsers(
          links.usersManagementLink
        );

        if (response && response.length > 0) {
          const userObjects = response.map((user) => ({
            username: user.username,
            createdDateTimeUtc: dateTimeHelper.getLocalDateTime(user.createdTimestamp),
            description: user.attributes?.Description?.[0],
            selected: false
          }));

          return userObjects;
        }

        return [];
      } catch (error) {
        console.error("Error fetching users list:", error);
        return [];
      }
    };

    const fetchUsersInfo = async () => {
      try {
        const response = await deviceApi.getUsersInfo(
          links.usersManagementLink
        );

        if(response.success) {        
          return response.data;
        }

        return [];
      } catch (error) {
        console.error("Error fetching usersInfo list:", error);
        return [];
      }
    };

    const fetchUsersAsync = async () => {
      const activeUsersInfo = await fetchUsersInfo();
      const activeUsernames = activeUsersInfo.map(ui => ui.username);
      const users = await fetchUsers();
      const updatedUsers = users.map((user) => ({
        ...user,
        isActive: activeUsernames.includes(user.username),
        deviceProfiles: activeUsersInfo.find(info => info.username === user.username)?.deviceProfiles
      }));

      setUsersList(updatedUsers);
      setFilteredUsersList(updatedUsers);
    };

    fetchUsersAsync();
  }, []);
  
  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        {usersList.length > 0 ? (
          <>
            <div className={classes["add-device-message-container"]}>
              <AddUserMessage
                setUserMessagesList={setUserMessagesList}
                usersList={usersList}
                setUsersList={setUsersList}
              />
            </div>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["user-header"]}>
                <SortableHeader
                  fields={userFields}
                  list={usersList}
                  setFilteredList={setFilteredUsersList}
                  classes={userHeaderClasses}
                  filters={filters}
                  dictionaryMapper={usersManagementDictionaryMapper}
                />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["userW-row"]}>
                <div className={classes["rows-container"]}>
                  {filteredUsersList.map((u) => (
                    <UserRow
                      user={u}
                      setUsersList={setUsersList}
                    />
                  ))}
                </div>
              </div>
              <div className={classes["aside-for-body"]}></div>
            </div>
          </>
        ) : (
          <h2>{usersManagementDictionary.noUsersWithDevices}</h2>
        )}
      </div>
    )
  );
};

export default UsersManagement;
