import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { deviceApi } from "../../../../api/deviceApi";
import { useState } from "react";
import { links } from "../../../../enums/links";

const TakeDeviceAwayButtonWrapper = ({
  deviceId,
  label,
  setDeviceList,
  currentUsername,
  ownerUsername
}) => {
  const [pressed, setPressed] = useState(false);
  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };
  
  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };  
  
  const handleClick = () => {
    deviceApi.takeDeviceAwayFromUser(deviceId, links.devicesManagementLink).then((response) => {
      if (response.success) {
        setDeviceList((prevState) => {
          const updatedList = prevState.map((dev) => {
            if (dev.id === deviceId) {
              return {
                ...dev,
                ownerUsername: currentUsername,
              };
            }

            return dev;
          });

          return updatedList;
        });
      }
    });
  };

  return (
    currentUsername !== ownerUsername && (
      <Button
        className={`${buttonClasses["take-device-away-button"]} ${
          buttonClasses[setPressedClass()]
        }`}
        startIcon="start"
        onClick={() => handleClick()}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {label}
      </Button>
    )
  );
};

export default TakeDeviceAwayButtonWrapper;