import React, { useState } from "react";
import classes from "../../css/pages/devices-list/deviceMessagesPanel.module.css";
import { dateTimeHelper } from "../../../helpers/dateTimeHelper";
import { deviceMessagesDictionary } from "../../../dictionary/ua/deviceMessagesDictionary";
import { iconManager } from "../../../helpers/iconManager";
import { iconType } from "../../../enums/iconType";
import { messageType } from "../../../enums/messageType";
import { deviceMessageTypeClassnameHelper } from "../../../helpers/deviceMessageTypeClassnameHelper";
import { deviceMessageCategory } from "../../../mappers/deviceMessageMapper";

const DeviceMessagesPanel = ({ deviceMessages }) => {
  const [
    showDeviceMessagesCheckboxSelected,
    setShowDeviceMessagesCheckboxSelected,
  ] = useState(true);

  const getIconByMessageType = (type) => {
    switch (type) {
      case messageType.DeviceGoesOffline:
        return iconManager.getIconByType(iconType.information);
      case messageType.DeviceGoesOnline:
        return iconManager.getIconByType(iconType.information);
      case messageType.LowBatteryVoltageLevel:
        return iconManager.getIconByType(iconType.warning);
      case messageType.LowGsmBalance:
        return iconManager.getIconByType(iconType.error);
      case messageType.ScheduleFinished:
        return iconManager.getIconByType(iconType.information);
      case messageType.MessageFromAdmin:
        return iconManager.getIconByType(iconType.admin);
      default:
        return iconManager.getIconByType(iconType.information);
    }
  };

  const handleShowDeviceMessagesClick = () => {
    setShowDeviceMessagesCheckboxSelected((prev) => !prev);
  };

  return (
    <div className={classes["device-messages-panel-wrapper"]}>
      <div className={classes["show-device-messages-panel-checkbox-wrapper"]}>
        <label htmlFor="show-device-messages-checkbox">
          {deviceMessagesDictionary.showDeviceMessageCheckboxText}
        </label>
        <input
          id="show-device-messages-panel-checkbox"
          type="checkbox"
          className={classes["show-device-messages-panel-checkbox"]}
          onChange={handleShowDeviceMessagesClick}
          checked={showDeviceMessagesCheckboxSelected}
        />
      </div>

      <div
        className={`${classes["device-message-row-wrapper"]} ${
          showDeviceMessagesCheckboxSelected ? classes.show : ""
        }`}
      >
        {deviceMessages.length > 0 &&
  deviceMessages
    .sort((a, b) => {
      if (a.messageType === deviceMessageCategory.MessageFromAdmin && b.messageType !== deviceMessageCategory.MessageFromAdmin) {
        return -1;
      }
      if (a.messageType !== deviceMessageCategory.MessageFromAdmin && b.messageType === deviceMessageCategory.MessageFromAdmin) {
        return 1;
      }
      
      return 0;
    })
    .map((deviceMessage) => (
      <div
        key={deviceMessage.id} // Assuming each message has a unique id
        className={`${classes["device-message-row"]} ${
          classes[deviceMessageTypeClassnameHelper.getClassNameByDeviceMessageType(deviceMessage.messageType)]
        }`}
      >
        <div>{getIconByMessageType(deviceMessage.messageType)}</div>
        <div>{deviceMessage.message}</div>
        <div>{dateTimeHelper.getLocalDateTime(deviceMessage.createdDateTimeUtc)}</div>
      </div>
    ))}

      </div>
    </div>
  );
}

export default DeviceMessagesPanel;