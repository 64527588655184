import classes from "../../../css/assets/icons/gsmAudioMode/monoGsmAudioMode.module.css";

const MonoGsmAudioMode = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="4.5" r="3.5" fill="#343434" />
          <path
            d="M7 14C7 11.2386 9.23858 9 12 9H13C15.7614 9 18 11.2386 18 14V23H7V14Z"
            fill="#343434"
          />
        </svg>
      </span>

      <span>
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 8.00001C17.2554 6.24023 16.4391 4.60967 15.1766 3.35951C13.9142 2.10934 12.2758 1.30893 10.5137 1.08156C8.75159 0.854198 6.96362 1.21249 5.4252 2.10126C3.88678 2.99002 2.68325 4.35995 2 6.00001M1.5 2.00001V6.00001H5.5M1.5 10C1.74456 11.7598 2.56093 13.3904 3.82336 14.6405C5.08579 15.8907 6.72424 16.6911 8.48633 16.9185C10.2484 17.1458 12.0364 16.7875 13.5748 15.8988C15.1132 15.01 16.3168 13.6401 17 12M17.5 16V12H13.5"
            stroke="#343434"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>

      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default MonoGsmAudioMode;
