import LockOpenIcon from '@mui/icons-material/LockOpen';
import classes from "../../../css/assets/icons/schedule-accessibility/sharedScheduleIcon.module.css";

const SharedScheduleIcon = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span>
        <LockOpenIcon />
      </span>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default SharedScheduleIcon;
