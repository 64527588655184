import { useState } from "react";
import classes from "../../css/pages/devices/deviceRow.module.css";
import DeleteButton from "../../assets/buttons/DeleteButton";
import { deviceApi } from "../../../api/deviceApi";
import DeleteItemsDialog from "../../assets/dialogs/DeleteItemsDialog.jsx";
import { deviceProfileMapper } from "../../../enums/deviceProfile";
import { links } from "../../../enums/links";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import{ deleteDeviceDialogDictionary } from "../../../dictionary/ua/deleteDeviceDialogDictionary.js";
import UList from "../../assets/lists/UList";

const DeviceRow = ({device, setDeviceList, isAdmin}) => {
  const [open, setOpen] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(false);
  const setSelectedToDeleteStatus = () => {
    return selectedToDelete === true ? "selected-to-delete" : "";
  };

  const handleChange = () => {
    setSelectedToDelete((prev) => !prev);
    setDeviceList((prevState) => {
      return prevState.map((dev) => {
        if (dev.id === device.id) {
          return {
            ...dev,
            toDelete: !dev.toDelete
          };
        }
        return dev;
      });
    });  
  };

  const handleOpenDeleteDeviceDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteDeviceDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      deviceApi.delete(device.id).then((response) => {
        if (response.success === true) {
          setDeviceList((prevState) => {
            return prevState.filter((dev) => device.id !== dev.id);
          });
        }
      });
    }
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div
          className={`${classes["row"]} ${
            classes[setSelectedToDeleteStatus()]
          }`}
        >
          <div className={classes["clientId-column"]}>{device.clientId}</div>
          <div className={classes["clientName-column"]}>{device.name}</div>
          <div className={classes["profile-column"]}>
            {deviceProfileMapper[device.profile]}
            <Link key="edit" to={links.getDeviceEditLink(device.id)}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
          </div>
          <div className={classes["select-device-column"]}>
            <span>
              <input
                type="checkbox"
                className={classes["select-device-checkbox"]}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className={classes["delete-device-column"]}>
            <DeleteButton onClick={handleOpenDeleteDeviceDialog} />
            <DeleteItemsDialog
              open={open}
              onClose={handleCloseDeleteDeviceDialog}
              items={[device.clientId]}
              deleteItemDialogDictionary={deleteDeviceDialogDictionary}
              UList={UList}
            />
          </div>
          {isAdmin && (
            <div className={classes["ownerUsername-column"]}>
              {device.ownerUsername}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceRow;
