import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { certificatesApi } from "../../../../api/certificatesApi";
import { useState } from "react";
import { certificatesManagementDictionary } from "../../../../dictionary/ua/certificatesManagementDictionary";
import { links } from "../../../../enums/links";

const DownloadCertificateButtonWrapper = ({
  certificate
}) => {
  const [pressed, setPressed] = useState(false);
  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };
  
  const handleClick = async () => {
    const response = await certificatesApi.download(certificate.serialNumber, links.getCertificatesManagementByDeviceEditLink(certificate.deviceId));
    if (response) {
        const blob = new Blob([response], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${certificate.commonName}_${certificate.serialNumber}_client_certificate.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Button
      className={`${buttonClasses["download-certificate-button"]} ${
        buttonClasses[setPressedClass()]
      }`}
      startIcon="start"
      onClick={() => handleClick()}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {certificatesManagementDictionary.downloadCertificateButtonLabel}
    </Button>
  );
};

export default DownloadCertificateButtonWrapper;
