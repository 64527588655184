import { addDeviceDictionary } from "../../dictionary/ua/addDeviceDictionary";
import { deviceProfile } from "../../enums/deviceProfile";

const deviceProfileNameMapping = {
  [deviceProfile.imitator]: addDeviceDictionary.imitatorProfileText,
  [deviceProfile.remoteSwitch]: addDeviceDictionary.remoteSwitchProfileText,
  [deviceProfile.gsmGate]: addDeviceDictionary.gsmGateProfileText,
};

const deviceProfileDictionaryMapper = {
  map: (profile) => deviceProfileNameMapping[profile] || null,
};

export default deviceProfileDictionaryMapper;
