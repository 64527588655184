import classes from "../../../css/deviceStatus.module.css";
import RadioAudioModePicker from "../../../assets/icons/radioAudioMode/RadioAudioModePicker";

const RadioAudioModeStatus = ({ device }) => {
  return (
    <div className={classes["device-status-wrapper"]}>
      <div>
        <RadioAudioModePicker radioAudioMode={device?.radioAudioMode} />
      </div>
    </div>
  );
};

export default RadioAudioModeStatus;
