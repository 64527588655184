import axios from "axios";
import cookieHelper from "../helpers/cookieHelper";
import windowOperations from "../helpers/windowOperations";
import { links } from "../enums/links";

const headers = {
  "Content-Type": "application/json",
  "X-AF-Token": cookieHelper.getAntiForgeryToken()
}

export const axiosClient = axios.create({  
  baseURL: process.env?.REACT_APP_API_BASE_URL,
  headers: headers
});

export const errorHandler = (error, redirectPage) => {
  const statusCode = error.response?.status;
  if (statusCode === 401) {
    windowOperations.goToLink(links.getLoginLink(redirectPage));
  }
  else {
    console.log(error);
  }

  return Promise.reject(error);
};

axiosClient.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

export const handleApiCall = async (apiCall, redirectPage) => {
  try {
    const response = await apiCall();
    return response.data;
  } catch (error) {
    return errorHandler(error, redirectPage);
  }
};
