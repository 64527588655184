import classes from "../../../css/assets/icons/radioAudioMode/phoneRadioAudioMode.module.css";

const PhoneRadioAudioMode = ({children, startIcon}) => {
  return (
    <div className={classes.wrapper}>
       <span className={classes[startIcon]}>
        <svg
          width="24"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.7072 12.7036C20.592 11.9032 19.974 11.2792 19.1784 11.0932C16.086 10.3684 15.3108 8.58762 15.18 6.61242C14.6316 6.51042 13.656 6.40002 12 6.40002C10.344 6.40002 9.3684 6.51042 8.82 6.61242C8.6892 8.58762 7.914 10.3684 4.8216 11.0932C4.026 11.2804 3.408 11.9032 3.2928 12.7036L2.6964 16.834C2.4864 18.2884 3.5544 19.6 5.04 19.6H18.96C20.4444 19.6 21.5136 18.2884 21.3036 16.834L20.7072 12.7036ZM12 16.5904C10.326 16.5904 8.9688 15.2464 8.9688 13.5904C8.9688 11.9344 10.326 10.5904 12 10.5904C13.674 10.5904 15.0312 11.9344 15.0312 13.5904C15.0312 15.2464 13.6728 16.5904 12 16.5904ZM23.94 5.20002C23.9112 3.40002 19.3296 0.401224 12 0.400024C4.6692 0.401224 0.0876039 3.40002 0.0600039 5.20002C0.0324039 7.00002 0.0852039 9.34242 3.102 8.95242C6.6312 8.49522 6.414 7.26282 6.414 5.50122C6.414 4.27242 9.2844 3.97602 12 3.97602C14.7156 3.97602 17.5848 4.27242 17.586 5.50122C17.586 7.26282 17.3688 8.49522 20.898 8.95242C23.9136 9.34242 23.9676 7.00002 23.94 5.20002Z"
            fill="#343434"
          />
        </svg>
      </span>

      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default PhoneRadioAudioMode;
