import classes from "../../../css/assets/dropdowns/profiles/imitator.module.css";

const ImitatorProfile = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default ImitatorProfile;
