import { editScheduleDictionary } from "../../dictionary/ua/scheduling/editScheduleDictionary";

export const editScheduleErrorMapper = {
    FailedToUpdateSchedule: editScheduleDictionary.scheduleNotUpdatedErrorText,
    FailedToRetrieveSchedule: editScheduleDictionary.scheduleNotRetrievedErrorText   
};

export const editScheduleErrorCode = {
    FailedToUpdateSchedule: "FailedToUpdateSchedule",
    FailedToRetrieveSchedule: "FailedToRetrieveSchedule"
};