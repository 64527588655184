export const setRadioChannelForTransceiverOption = {
    setRadioChannelForFirstTransceiver: "set-radio-channel-for-first-transceiver",
    setRadioChannelForSecondTransceiver: "set-radio-channel-for-second-transceiver",
    setRadioChannelForBothTransceivers: "set-radio-channel-for-both-transceivers"
};

export const setRadioChannelForTransceiverOptionLabels = {
    "set-radio-channel-for-first-transceiver": (radioChannel) => `RF1C=${radioChannel};`,
    "set-radio-channel-for-second-transceiver": (radioChannel) => `RF2C=${radioChannel};`,
    "set-radio-channel-for-both-transceivers": (radioChannel) => `RFC=${radioChannel};`
};