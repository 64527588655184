import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const firmwareApi = {
  getById: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/firmware/${id}`,
          method: "GET",
        }),
      redirect
    );
  },
  get: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/firmware`,
          method: "GET",
        }),
      redirect
    );
  },
  upload: async function (data, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/firmware`,
          method: "POST",
          data: data
        }),
      redirect
    );
  },
  delete: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/firmware/${id}`,
          method: "DELETE",
        }),
      redirect
    );
  },
};
