import classes from "../../../css/assets/dropdowns/diStatus/open.module.css";

const Open = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default Open;
