import classes from "../../css/assets/relay/offRelay.module.css";

const OffRelay = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default OffRelay;
