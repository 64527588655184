import React, { useState } from "react";
import classes from "../../css/pages/scheduling/addSchedule.module.css";
import { useForm } from "react-hook-form";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import Button from "../../assets/buttons/Button";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import { addScheduleErrors } from "../../../enums/errorMappers/addScheduleErrors";
import { scheduleApi } from "../../../api/scheduleApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { addScheduleInputSchema } from "../../../validation/validationSchemas";
import { addScheduleDictionary } from "../../../dictionary/ua/scheduling/addScheduleDictionary";
import { links } from "../../../enums/links";

function AddSchedule({ scheduleString, setScheduleList }) {
  const [apiErrors, setApiErrors] = useState([]);
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [schedule, setSchedule] = useState({
    name: "",
    description: "",
    scheduleString: ""
  });

  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(addScheduleInputSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("name").invalid || getFieldState("description").invalid;
  }

  const onSubmit = async () => {
    let payload = {
      name: schedule.name,
      description: schedule.description,
      scheduleString: scheduleString
    };

    setDisabled(true);
    scheduleApi.create(payload, links.schedulingLink).then((response) => {
      if (response.success === true) {
        setScheduleList((prevState) => {
            payload.id = response.data;
            return [payload, ...prevState];
          });

          setSchedule({
            name: "",
            description: ""
          });
      }
      else {
        setApiErrors([ addScheduleErrors.FailedToCreateSchedule ]);
      }
    });
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleChange = (event) => {
    if (getFieldState("name").isDirty && !getFieldState("name").invalid) {
      setDisabled(false);
    }

    setSchedule((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  return (
    <div className={classes["add-schedule-wrapper"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="name">
                {addScheduleDictionary.titleLabelText}
              </label>
            </span>
            <OutlinedInput
              id="name"
              type="text"
              name="name"
              size="small"
              value={schedule.name}
              {...register("name")}
            />
            <FormHelperText>{errors?.name?.message}</FormHelperText>
            {apiErrors.map((e) => (
              <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
            ))}
          </FormControl>
        </div>

        <div>
          <FormControl
            className={classes["form-control"]}
            error={getFieldState("description").invalid}
            onChange={handleChange}
          >
            <span className={classes["label-container"]}>
              <label htmlFor="description">
                {addScheduleDictionary.descriptionLabelText}
              </label>
            </span>
            <OutlinedInput
              id="description"
              type="text"
              name="description"
              size="small"
              value={schedule.description}
              {...register("description")}
            />
            <FormHelperText>{errors?.description?.message}</FormHelperText>
          </FormControl>
        </div>

        <div>
          <Button
            className={`${buttonClasses["add-schedule-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {addScheduleDictionary.addScheduleButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddSchedule;
