import React, { useEffect, useState } from "react";
import classes from "../css/commands/radioAudioMode.module.css";
import { topicType } from "../../enums/topicType";
import { getTopicName } from "../../helpers/getTopicName";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { radioAudioModeCommandInputSchema } from "../../validation/validationSchemas";
import buttonClasses from "../css/assets/buttons/button.module.css";
import Button from "../assets/buttons/Button";
import ConfirmIcon from "../assets/icons/ConfirmIcon";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import OffRadioAudioMode from "../assets/icons/radioAudioMode/OffRadioAudioMode";
import PhoneRadioAudioMode from "../assets/icons/radioAudioMode/PhoneRadioAudioMode";
import BroadcastRadioAudioMode from "../assets/icons/radioAudioMode/BroadcastRadioAudioMode";
import { devicesListDictionary } from "../../dictionary/ua/devicesListDictionary";
import { mqttApi } from "../../api/mqttApi";
import { links } from "../../enums/links";

function RadioAudioModeCommand({ selectedDevicesClientIds }) {
  const [notValidationErrorText, setNotValidationError] = useState("");
  const [radioAudioMode, setRadioAudioMode] = useState("");
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(radioAudioModeCommandInputSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    setRadioAudioMode("");
    setDisabled(true);

    const datas = {
      topics: [],
      payloads: [],
    };

    selectedDevicesClientIds.forEach((clientId) => {
      datas.topics.push(getTopicName(clientId, topicType.radioAudioModeCommand));
      datas.payloads.push(String(data.radioAudioMode));
    });

    const response = mqttApi.sendMessage(datas, links.devicesLink);
    console.log(response);
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleChange = (event) => {
    if (radioAudioMode && isAnyDeviceSelected()) {
      setDisabled(false);
    }
    else {
      setDisabled(true);
    }

    setRadioAudioMode(event.target.value);
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const isError = () => {
    return getFieldState("radioAudioMode").invalid || notValidationErrorText !== "";
  }

  const isAnyDeviceSelected = () => selectedDevicesClientIds?.length > 0;

  useEffect(() => {    
    setDisabled(radioAudioMode === "" || !isAnyDeviceSelected());
}, [isAnyDeviceSelected]);

  return (
    <div className={classes.command}>
      <span>{devicesListDictionary.radioAudioModeDropDownLabel}</span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
          >
            <Select
              id="radioAudioMode"
              className={classes["radio-audio-mode-select"]}
              value={radioAudioMode}
              size="small"
              {...register("radioAudioMode")}
              onChange={handleChange}
            >
              <MenuItem value={0}>
                <OffRadioAudioMode startIcon="end">{devicesListDictionary.offRadioAudioModeText}</OffRadioAudioMode>
              </MenuItem>
              <MenuItem value={1}>
                <PhoneRadioAudioMode startIcon="start">{devicesListDictionary.phoneRadioAudioModeText}</PhoneRadioAudioMode>
              </MenuItem>
              <MenuItem value={2}>
                <BroadcastRadioAudioMode startIcon="start">{devicesListDictionary.broadcastRadioAudioModeText}</BroadcastRadioAudioMode>
              </MenuItem>
            </Select>
            <FormHelperText>{errors?.radioAudioMode?.message}</FormHelperText>
          </FormControl>
          <FormHelperText className={classes["not-validation-error-text"]}>{notValidationErrorText}</FormHelperText>
          <Button
            className={`${buttonClasses["radio-audio-mode-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            icon={<ConfirmIcon />}
            startIcon="start"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesListDictionary.radioAudioModeButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default RadioAudioModeCommand;
