import { deviceStatusType } from "../../../../enums/deviceStatus";
import React from "react";
import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import classes from "../../../css/statuses/gsmPhone.module.css";
import { IconButton, Popover, Tooltip, Typography } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import config from "../../../../config";

const GsmPhone = ({ gsmBalance, gsmBalanceLastUpdated, phoneNumber }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTooltipTitle = (date) => {
    if(!isNaN(Date.parse(date))) {
      return `${devicesListDictionary.lastUpdatedStatusText} ${date.toLocaleString(config.localization.ua)}`;
    }
    
    return "";
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if ((gsmBalance === deviceStatusType.notApplicable || !gsmBalance) 
      && (phoneNumber === deviceStatusType.notApplicable || !phoneNumber))
    return <span>{deviceStatusType.notApplicable}</span>;

    return (
      <div className={classes["gsm-phone"]}>
        <div>
          <Tooltip
            title={getTooltipTitle(gsmBalanceLastUpdated)}
            arrow
            placement="bottom"
          >
            <PhoneAndroid onClick={handleClick} />
          </Tooltip>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className={classes["close-button"]}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <Typography className={classes["gsm-status-typography"]}>
            <span>{phoneNumber}</span>
            <span>{gsmBalance}{devicesListDictionary.uaCurrencyText}</span>
          </Typography>
        </Popover>
      </div>
    );
};

export default GsmPhone;
