import classes from "../../../css/pages/scheduling/commands/setGsmMode.module.css";
import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { FormControl, FormHelperText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";
import { setGsmModeOption, setGsmModeOptionLabels } from "../../../../enums/scheduling/setGsmModeOption";
import { scheduleDesigner } from "../../../../dictionary/ua/scheduling/scheduleDesigner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setGsmModeSchema } from "../../../../validation/validationSchemas";
import { gsmModeSelect } from "../../../../enums/scheduling/gsmModeSelect";
import { mp3PlaybackSelect } from "../../../../enums/mp3PlaybackSelect";
import { mp3PlaybackDictionary } from "../../../../dictionary/ua/scheduling/mp3PlaybackDictionary";

const SetGsmMode = ({ insertAtCursor }) => {
  const [gsmMode, setGsmMode] = useState("");
  const [mp3Playback, setMp3Playback] = useState("");
  const [trackNumber, setTrackNumber] = useState("");
  const [result, setResult] = useState("");
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(setGsmModeSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("gsmMode").invalid || getFieldState("mp3Playback").invalid || getFieldState("trackNumber").invalid;
  }

  const onSubmit = () => {
    console.log("GsmMode " + gsmMode);
    console.log("Mp3Playback " + mp3Playback);
    console.log("Tracknumber " + trackNumber);

    insertAtCursor(setGsmModeOptionLabels[setGsmModeOption.setGsmMode](result));
  };

  const handleChange = (event) => {
    if (event.target.value !== gsmModeSelect.mp3Playback.value) {
      setMp3Playback("");
    }
    
    setGsmMode(event.target.value)
    setResult(event.target.value);
  };

  const handleMp3PlaybackChange = (event) => {    
    if (event.target.value !== mp3PlaybackSelect.trackNumber.value) {
      setTrackNumber("");
      setResult(`${gsmMode}${event.target.value}`);
    }
    else {
      setResult(`${gsmMode}`);
    }
    
    setMp3Playback(event.target.value);
  };

  const handleTrackNumberChange = (event) => {
    setTrackNumber(event.target.value);
    if(mp3Playback !== mp3PlaybackSelect.trackNumber.value) {
      setResult(`${gsmMode}${mp3Playback}`);
    }
    else {
      setResult(`${gsmMode}${event.target.value}`);
    }
  };

  return (
    <div className={classes["container"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button className={`${buttonClasses["set-gsm-mode-button"]}`}>
          {scheduleDesigner.setGsmModeButtonText}
        </Button>
        <div className={classes["gsm-mode-container"]}>
          <FormControl className={classes["form-control"]} error={isError()}>
            <Select
              className={classes["gsm-mode-select"]}
              value={gsmMode}
              size="small"
              {...register("gsmMode")}
              onChange={handleChange}
            >
              <MenuItem value={gsmModeSelect.disconnected.value}>{gsmModeSelect.disconnected.text}</MenuItem>
              <MenuItem value={gsmModeSelect.echo.value}>{gsmModeSelect.echo.text}</MenuItem>
              <MenuItem value={gsmModeSelect.mp3Playback.value}>{gsmModeSelect.mp3Playback.text}</MenuItem>
            </Select>
            <FormHelperText>{errors?.gsmMode?.message}</FormHelperText>
          </FormControl>
          
          {gsmMode === gsmModeSelect.mp3Playback.value && (
            <FormControl className={classes["form-control"]} error={isError()}>
              <Select
                className={classes["mp3-playback-select"]}
                value={mp3Playback}
                size="small"
                {...register("mp3Playback")}
                onChange={handleMp3PlaybackChange}
              >
                <MenuItem value={mp3PlaybackSelect.nextTrack.value}>{mp3PlaybackDictionary.nextTrack}</MenuItem>
                <MenuItem value={mp3PlaybackSelect.randomTrack.value}>{mp3PlaybackDictionary.randomTrack}</MenuItem>
                <MenuItem value={mp3PlaybackSelect.trackNumber.value}>{mp3PlaybackDictionary.trackNumber}</MenuItem>
              </Select>
              <FormHelperText>{errors?.mp3Playback?.message}</FormHelperText>
            </FormControl>
          )}

          {mp3Playback === mp3PlaybackSelect.trackNumber.value && (
            <FormControl
              className={classes["form-control"]}
              error={isError()}
              onChange={handleTrackNumberChange}
            >
              <OutlinedInput
                type="text"
                size="small"
                value={trackNumber}
                name="trackNumber"
                {...register("trackNumber")}
              />
              <FormHelperText>{errors?.trackNumber?.message}</FormHelperText>
            </FormControl>
          )}
        </div>
        <div className={classes["information"]}>
          {scheduleDesigner.setGsmModeInformationText}
        </div>
      </form>
    </div>
  );
};

export default SetGsmMode;