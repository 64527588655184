import classes from "../../../css/assets/icons/gsmAudioMode/echoGsmAudioMode.module.css";

const EchoGsmAudioMode = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="4.5" r="3.5" fill="#343434" />
          <path
            d="M7 14C7 11.2386 9.23858 9 12 9H13C15.7614 9 18 11.2386 18 14V23H7V14Z"
            fill="#343434"
          />
        </svg>
      </span>

      <span>
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.00687e-07 3.5C-0.000149458 3.42165 0.0156789 3.34458 0.0459464 3.2763C0.0762139 3.20801 0.119891 3.15083 0.172728 3.11031L3.44727 0.56793C3.50248 0.525024 3.56571 0.501601 3.63044 0.500079C3.69516 0.498558 3.75903 0.518994 3.81546 0.559276C3.87134 0.598933 3.91789 0.656765 3.95033 0.726827C3.98276 0.796888 3.99991 0.87665 4 0.957911L4 6.04209C3.99991 6.12335 3.98276 6.20311 3.95033 6.27317C3.91789 6.34324 3.87134 6.40107 3.81545 6.44072C3.75903 6.48101 3.69516 6.50144 3.63044 6.49992C3.56571 6.4984 3.50248 6.47498 3.44727 6.43207L0.172728 3.88969C0.11989 3.84917 0.0762138 3.79199 0.0459464 3.7237C0.0156789 3.65542 -0.000149471 3.57835 5.00687e-07 3.5Z"
            fill="#343434"
          />
          <rect
            x="12"
            y="4.5"
            width="9"
            height="2"
            transform="rotate(-180 12 4.5)"
            fill="#343434"
          />
          <path
            d="M13 9.5C13.0001 9.57835 12.9843 9.65542 12.9541 9.7237C12.9238 9.79199 12.8801 9.84917 12.8273 9.88969L9.55273 12.4321C9.49752 12.475 9.43429 12.4984 9.36956 12.4999C9.30484 12.5014 9.24097 12.481 9.18454 12.4407C9.12866 12.4011 9.08211 12.3432 9.04967 12.2732C9.01724 12.2031 9.00009 12.1233 9 12.0421L9 6.95791C9.00009 6.87665 9.01724 6.79689 9.04967 6.72683C9.08211 6.65676 9.12866 6.59893 9.18455 6.55928C9.24097 6.51899 9.30484 6.49856 9.36956 6.50008C9.43429 6.5016 9.49752 6.52502 9.55273 6.56793L12.8273 9.11031C12.8801 9.15083 12.9238 9.20801 12.9541 9.2763C12.9843 9.34458 13.0001 9.42165 13 9.5Z"
            fill="#343434"
          />
          <rect x="1" y="8.5" width="9" height="2" fill="#343434" />
        </svg>
      </span>

      <span>
        <svg
          width="18"
          height="24"
          viewBox="0 0 18 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.87742 8.71428L3.19355 7.12619C3.9871 6.41429 4.88245 5.86192 5.87961 5.46909C6.87677 5.07627 7.9169 4.88022 9 4.88095C10.0839 4.88095 11.1244 5.077 12.1215 5.46909C13.1187 5.86119 14.0137 6.41356 14.8065 7.12619L13.1226 8.71428C12.5613 8.18492 11.9323 7.77859 11.2355 7.49529C10.5387 7.21198 9.79355 7.0707 9 7.07143C8.20645 7.07143 7.46129 7.21308 6.76452 7.49638C6.06774 7.77968 5.43871 8.18565 4.87742 8.71428ZM1.62581 5.53809L0 4.00476C1.21935 2.87302 2.59858 2.00595 4.13768 1.40357C5.67677 0.80119 7.29755 0.5 9 0.5C10.7032 0.5 12.3244 0.80119 13.8635 1.40357C15.4026 2.00595 16.7814 2.87302 18 4.00476L16.3742 5.53809C15.3871 4.6254 14.2548 3.92262 12.9774 3.42976C11.7 2.9369 10.3742 2.69048 9 2.69048C7.62581 2.69048 6.3 2.9369 5.02258 3.42976C3.74516 3.92262 2.6129 4.6254 1.62581 5.53809ZM6.67742 23.5C6.03871 23.5 5.49174 23.2857 5.03652 22.8571C4.58129 22.4285 4.35406 21.9126 4.35484 21.3095V12.5476C4.35484 11.9452 4.58245 11.4294 5.03768 11C5.4929 10.5707 6.03948 10.3564 6.67742 10.3571H11.3226C11.9613 10.3571 12.5083 10.5718 12.9635 11.0011C13.4187 11.4305 13.6459 11.946 13.6452 12.5476V21.3095C13.6452 21.9119 13.4175 22.4278 12.9623 22.8571C12.5071 23.2864 11.9605 23.5007 11.3226 23.5H6.67742ZM11.3226 21.3095V12.5476H6.67742V21.3095H11.3226Z"
            fill="#343434"
          />
        </svg>
      </span>

      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default EchoGsmAudioMode;
