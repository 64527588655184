import { useState } from "react";
import { dateTimeHelper } from "../../../helpers/dateTimeHelper";
import classes from "../../css/pages/user-messages/userMessageRow.module.css";
import { links } from "../../../enums/links";
import { userMessagesApi } from "../../../api/userMessagesApi";


const UserMessageRow = ({ userMessage, setUserMessagesList }) => {
  const [open, setOpen] = useState(false);
  const handleOpenDeleteUserMessageDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteUserMessageDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      userMessagesApi.delete(userMessage.id, links.getUserMessagesManagementByUsernameEditLink(userMessage.username)).then((response) => {
        if (response.success === true) {
          setUserMessagesList((prevState) => {
            return prevState.filter((um) => userMessage.id !== um.id);
          });
        }
      });
    }
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div className={`${classes["row"]}`}>
          <div className={classes["message-column"]}>
            {userMessage.message}
          </div>
          <div className={classes["createdDate-column"]}>
            {dateTimeHelper.getLocalDateTime(userMessage.createdDateTimeUtc)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessageRow;