import classes from "../../../css/pages/admin/user-management/userRow.module.css";
import { links } from "../../../../enums/links";
import { usersManagementDictionary } from "../../../../dictionary/ua/usersManagementDictionary";
import { Link } from "react-router-dom";
import { useState } from "react";
import deviceProfileDictionaryMapper from "../../../dictionaryMapper/deviceProfileDictionaryMapper";

const UserRow = ({
  user,
  setUsersList
}) => {
  const [selected, setSelected] = useState(false);
  const setSelectedStatus = () => {
    return selected === true ? "selected" : "";
  };

  const handleChange = () => {
    const isSelected = !selected;
    setSelected(isSelected);
    setUsersList((prevState) => {
      return prevState.map((u) => {
        if (u.username === user.username) {
          return {
            ...u,
            selected: !u.selected,
          };
        }

        return u;
      });
    });
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div className={`${classes["row"]} ${classes[setSelectedStatus()]}`}>
          <div className={classes["username-column"]}>{user.username}</div>
          <div className={classes["description-column"]}>
            {user.description}
          </div>
          <div className={classes["createdDate-column"]}>
            {user.createdDateTimeUtc}
          </div>
          <div className={classes["isActive-column"]}>
            {user.isActive
              ? usersManagementDictionary.yesText
              : usersManagementDictionary.noText}
          </div>
          <div className={classes["deviceProfiles-column"]}>
            {Array.isArray(user.deviceProfiles)
              ? user.deviceProfiles
                  .map((p) => deviceProfileDictionaryMapper.map(p))
                  .join(" | ")
              : deviceProfileDictionaryMapper.map(user.deviceProfiles) || ""}
          </div>
          <div className={classes["user-messages-management-button-column"]}>
            <Link
              key="edit"
              to={links.getUserMessagesManagementByUsernameEditLink(
                user.username
              )}
            >
              {usersManagementDictionary.userMessagesManagementButtonLabel}
            </Link>
          </div>
          <div className={classes["select-item-column"]}>
            <span>
              <input
                type="checkbox"
                className={classes["select-item-checkbox"]}
                onChange={handleChange}
                checked={user.selected}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRow;