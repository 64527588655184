import classes from "../../../css/assets/icons/radioAudioMode/offRadioAudioMode.module.css";

const OffRadioAudioMode = ({children, startIcon}) => {
  return (
    <div className={classes.wrapper}>
      <span className={classes[startIcon]}>
        <svg
          width="23"
          height="15"
          viewBox="0 0 23 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.84314"
            y="9.31372"
            width="2"
            height="5"
            rx="1"
            fill="#343434"
          />
          <rect
            x="4.84314"
            y="5.31372"
            width="2"
            height="9"
            rx="1"
            fill="#343434"
          />
          <rect
            x="21.3676"
            y="6.10352e-05"
            width="2.53033"
            height="17.7123"
            rx="1.26516"
            transform="rotate(45 21.3676 6.10352e-05)"
            fill="#343434"
          />
          <rect
            x="8.84323"
            y="1.78918"
            width="2.53033"
            height="17.7123"
            rx="1.26516"
            transform="rotate(-45 8.84323 1.78918)"
            fill="#343434"
          />
        </svg>
      </span>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default OffRadioAudioMode;
