import classes from "../../../css/statuses/gsmStatus.module.css";
import GsmSignalLevelPicker from "../../../assets/icons/gsmSignalLevel/GsmSignalLevelPicker";
import GsmPhone from "../../../assets/icons/gsmPhone/GsmPhone";

const GsmStatus = ({ device }) => {
  return (
    <div className={classes["device-status-wrapper"]}>
      {device?.gsmInfos?.length > 0 &&
        device.gsmInfos.map((gsmInfo) => (
          <div className={classes["gsm-status-wrapper"]} key={gsmInfo.id}>
            <GsmSignalLevelPicker gsmSignalLevel={gsmInfo?.signalLevel} />
            <div>
              <GsmPhone
                gsmBalance={gsmInfo?.accountBalance}
                gsmBalanceLastUpdated={gsmInfo?.gsmBalanceStatusLastUpdated}
                phoneNumber={gsmInfo?.phoneNumber}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default GsmStatus;
