import React from "react";
import classes from "../../../../css/pages/scheduling/public-schedules/schedulesList.module.css";
import ScheduleRow from "./ScheduleRow";
import ScheduleHeader from "./ScheduleHeader";

const PublicSchedules = ({ scheduleList, setOwnedScheduleList, setSharedScheduleList }) => {
  return (
    <div className={classes["own-schedules-wrapper"]}>
      <div className={classes["header-container"]}>
        <div className={classes["schedule-header"]}>
          <ScheduleHeader
            setSharedScheduleList={setSharedScheduleList}
          />
        </div>
      </div>
      {scheduleList.length > 0 && (
        <div className={classes["body-container"]}>
          <div className={classes["schedule-row"]}>
            <div className={classes["rows-container"]}>
              {scheduleList.map((schedule) => (
                <ScheduleRow
                  key={schedule.id}
                  schedule={schedule}
                  setOwnedScheduleList={setOwnedScheduleList}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicSchedules;
