import classes from "../../../css/statuses/radioSilenceStatus.module.css";
import ClockIcon from "../../../assets/icons/ClockIcon";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import RadioSilenceIcon from "../../../assets/icons/RadioSilenceIcon";

const RadioSilenceStatus = ({ device }) => {
  return (
    <div className={classes["device-status-wrapper"]}>
      {device.radioSilence && device.radioSilence > 0 ? (
        <div className={classes["radio-silence-wrapper"]}>
          <span className={classes["radio-silence-icon-wrapper"]}>
            <RadioSilenceIcon />
          </span>
          <span>
            <ClockIcon className />
          </span>
          <span>{device.radioSilence}</span>
          <span>{devicesListDictionary.radioSilenceMinutesText}</span>
        </div>
      ) : (
        <div className={classes["radio-silence-wrapper"]}>
          <span>
            <ClockIcon className />
          </span>
          <span>{devicesListDictionary.zeroMinutesText}</span>
        </div>
      )}
    </div>
  );
};

export default RadioSilenceStatus;
