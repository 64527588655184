import { onlineStatus } from "../enums/onlineStatus";

export const deviceExtensionHelper = {
  getAllDateTimes: (device) => {
    const dateKeys = [
      "batteryVoltageLevelStatusLastUpdated",
      "deviceStateUpdatedDateTimeUtc",
      "gsm1BalanceStatusLastUpdated",
      "gsm1SignalLevelStatusLastUpdated",
      "gsm2SignalLevelStatusLastUpdated",
      "gsm3SignalLevelStatusLastUpdated",
      "gsm1BalanceStatusLastUpdated",
      "gsm2BalanceStatusLastUpdated",
      "gsm3BalanceStatusLastUpdated",
      "gsmAudioModeStatusLastUpdated",
      "radioAudioModeStatusLastUpdated",
      "radioSilenceStatusLastUpdated",
      "scheduleStatusLastUpdated",
      "updatedDateTimeUtc",
    ];

    return dateKeys.map((key) => device[key]);
  },
  getOnlineStatusClass: (online) =>
    online ? onlineStatus.online : onlineStatus.offline,
};