import { Cookies } from "react-cookie";
import { cookieType } from "../enums/cookieType";

var cookies = new Cookies();

const cookieHelper = {
    getAntiForgeryToken: () => cookies.get(cookieType.afToken)
};

export default cookieHelper;

