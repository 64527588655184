import { deviceMessagesDictionary } from "../dictionary/ua/deviceMessagesDictionary";

export const deviceMessageCategory = {
  MessageFromAdmin: "MessageFromAdmin",
};

export const deviceMessageTypeMapper = {
  mapDeviceMessageType: (messageType) => {
    if(messageType === deviceMessageCategory.MessageFromAdmin) {
      return deviceMessagesDictionary.messageFromAdmin;
    }
    else {
      return deviceMessagesDictionary.messageFromSystem;
    }
  }
}
