import classes from "../../../css/deviceStatus.module.css";
import GsmAudioModePicker from "../../../assets/icons/gsmAudioMode/GsmAudioModePicker";

const GsmAudioModeStatus = ({ device }) => {
  return (
    <div className={classes["device-status-wrapper"]}>
      <div>
        <GsmAudioModePicker gsmAudioMode={device?.gsmAudioMode} />
      </div>
    </div>
  );
};

export default GsmAudioModeStatus;
