import classes from "../../../css/deviceStatus.module.css";
import DIStatusPicker from "../../../assets/dropdowns/diStatus/DIStatusPicker";

const GsmGateDIStatus = ({ device }) => {
  return (
    <div className={classes["device-status-wrapper"]}>
      <div>
        <DIStatusPicker diStatus={device.digitalInput} />
      </div>
    </div>
  );
};

export default GsmGateDIStatus;
