const ConfirmIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.62327 14.3745C8.23913 14.7276 7.64804 14.7261 7.26579 14.3709L4.80883 12.0879C4.37566 11.6853 4.38445 10.9969 4.82774 10.6056L5.03529 10.4224C5.42067 10.0822 6.00119 10.0896 6.37777 10.4395L7.26216 11.2613C7.64591 11.6179 8.23981 11.6179 8.62356 11.2613L13.605 6.63251C13.9888 6.27593 14.5827 6.27593 14.9664 6.63251L15.2074 6.85643C15.6347 7.25346 15.6329 7.93042 15.2035 8.32517L8.62327 14.3745Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z"
        fill="white"
      />
    </svg>
  );
};

export default ConfirmIcon;
