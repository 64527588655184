import { deviceStatusType } from "../../../../enums/deviceStatus";
import EchoGsmAudioMode from "./EchoGsmAudioMode";
import MonoGsmAudioMode from "./MonoGsmAudioMode";
import OffGsmAudioMode from "./OffGsmAudioMode";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";

const GsmAudioModePicker = ({ gsmAudioMode }) => {
  const renderGsmAudioMode = (gsmAudioMode) => {
    const gsmAudioModes = {
      0: <OffGsmAudioMode/>,
      1: <EchoGsmAudioMode startIcon="start">{devicesListDictionary.ehoGsmAudioModeText}</EchoGsmAudioMode>,
      2: <MonoGsmAudioMode startIcon="start">{devicesListDictionary.monoGsmAudioModeText}</MonoGsmAudioMode>,
    };

    const component = gsmAudioModes[gsmAudioMode];
    if (component) return component;
    else return <>{deviceStatusType.notApplicable}</>;
  };

  return renderGsmAudioMode(gsmAudioMode);
};

export default GsmAudioModePicker;
