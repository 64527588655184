import React from "react";
import { deviceProfile } from "../../../enums/deviceProfile";
import ImitatorDeviceRow from "./profiles/imitator/DeviceRow";
import RemoteSwitchDeviceRow from "./profiles/remote-switch/DeviceRow";
import GsmGateDeviceRow from "./profiles/gsm-gate/DeviceRow";

const profileComponentMap = {
  [deviceProfile.imitator]: ImitatorDeviceRow,
  [deviceProfile.remoteSwitch]: RemoteSwitchDeviceRow,
  [deviceProfile.gsmGate]: GsmGateDeviceRow
};

const DeviceRowPicker = ({ device, setDeviceList }) => {
  const DeviceRowComponent = profileComponentMap[device?.profile] || ImitatorDeviceRow;
  return <DeviceRowComponent device={device} setDeviceList={setDeviceList} />;
};

export default DeviceRowPicker;
