import LockIcon from '@mui/icons-material/Lock';
import classes from "../../../css/assets/icons/schedule-accessibility/privateScheduleIcon.module.css";

const PrivateScheduleIcon = ({children}) => {
  return (
    <div className={classes.wrapper}>
      <span>
        <LockIcon />
      </span>
      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default PrivateScheduleIcon;
