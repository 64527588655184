import { useEffect, useState } from "react";
import classes from "../../../css/pages/admin/device-messages-management/deviceMessagesManagement.module.css";
import { documentTitleDictionary } from "../../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import useSetCurrentUserResult from "../../../hooks/useSetCurrentUserResult";
import { links } from "../../../../enums/links";
import { Link, useParams } from "react-router-dom";
import ReturnButton from "../../../assets/buttons/ReturnButton";
import DeviceMessageHeader from "./DeviceMessageHeader";
import DeviceMessageRow from "./DeviceMessageRow";
import { deviceMessagesApi } from "../../../../api/deviceMessagesApi";
import { deviceMessagesDictionary } from "../../../../dictionary/ua/deviceMessagesDictionary";
import AddDeviceMessage from "./AddDeviceMessage";

const DeviceMessagesManagement = () => {
  useDocumentTitle(documentTitleDictionary.devicesMessagesManagementTitle + " - " + documentTitleDictionary.mainTitle);
  const { deviceId } = useParams();
  const currentUserResult = useSetCurrentUserResult();
  const [deviceMessagesList, setDeviceMessagesList] = useState([]);

  const fetchDeviceMessagesByDeviceId = async (deviceId) => {
    try {
      const response = await deviceMessagesApi.getByDeviceId(deviceId, links.getDeviceMessagesManagementByDeviceEditLink(deviceId));
      if (response.data?.length > 0) {
        setDeviceMessagesList(response.data);
      }
    } catch (error) {
      console.error("Error fetching device messages list:", error);
    }
  };
  

  useEffect(() => {
    const fetchDeviceMessages = async () => {
      await fetchDeviceMessagesByDeviceId(deviceId);
    };

    fetchDeviceMessages();
  }, []);
  
  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        <div className={classes["add-device-message-container"]}>
          <AddDeviceMessage setDeviceMessagesList={setDeviceMessagesList} deviceId={deviceId} />
        </div>
        <div>
        <Link
            className={classes["return-to-device-management-link"]}
            to={links.devicesManagementPath}
          >
            <ReturnButton>
              {deviceMessagesDictionary.returnToDevicesManagementText}
            </ReturnButton>
          </Link>
        </div>
        {deviceMessagesList.length > 0 ? (
          <>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["device-message-header"]}>
                <DeviceMessageHeader />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["device-message-row"]}>
                <div className={classes["rows-container"]}>
                  {deviceMessagesList.map((deviceMessage) => (
                    <DeviceMessageRow
                      key={deviceMessage.id}
                      deviceMessage={deviceMessage}
                      setDeviceMessagesList={setDeviceMessagesList}
                    />
                  ))}
                </div>
              </div>
              <div className={classes["aside-for-body"]}></div>
            </div>
          </>
        ) : (
          <h2>
            {deviceMessagesDictionary.noAvailableDeviceMessagesText}
          </h2>
        )}
      </div>
    )
  );
};

export default DeviceMessagesManagement;
