import React, { useState, useEffect } from "react";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import classes from "../../css/pages/scheduling/scheduling.module.css";
import { scheduleApi } from "../../../api/scheduleApi";
import AddSchedule from "./AddSchedule";
import ScheduleBuilder from "./ScheduleBuilder";
import OwnSchedules from "./schedules-list/own-schedules/OwnSchedules";
import PublicSchedules from "./schedules-list/public-schedules/PublicSchedules";
import useSetCurrentUserResult from "../../hooks/useSetCurrentUserResult";
import scheduleMapper from "../../../mappers/scheduleMapper";
import { links } from "../../../enums/links";

const Scheduling = () => {
  useDocumentTitle(documentTitleDictionary.schedulingTitle + " - " + documentTitleDictionary.mainTitle);
  
  const [scheduleString, setScheduleString] = useState('');
  const [ownedScheduleList, setOwnedScheduleList] = useState([]);
  const [sharedScheduleList, setSharedScheduleList] = useState([]);
  const currentUserResult = useSetCurrentUserResult();

  const fetchOnlyOwnedScheduleList = async (search) => {
    try {
      const response = await scheduleApi.getOnlyOwnedByUser((search = ""), links.schedulingLink);
      if(response?.data?.length > 0) {
        const mappedSchedules = response.data.map((schedule) => scheduleMapper.mapToDeletableObject(schedule));
        setOwnedScheduleList(mappedSchedules);
      }
    } catch (error) {
      console.error("Error fetching owned schedules list:", error);
    }
  };

  const fetchSharedScheduleList = async (search) => {
    try {
      const response = await scheduleApi.getOnlySharedForUser((search = ""), links.schedulingLink);
      if(response?.data?.length > 0) {
        const mappedSchedules = response.data.map((schedule) => scheduleMapper.mapToDeletableObject(schedule));
        setSharedScheduleList(mappedSchedules);
      }
    } catch (error) {
      console.error("Error fetching shared schedules list:", error);
    }
  };

  useEffect(() => {
    const fetchDevicesAndSchedules = async () => {
      await Promise.all([
        fetchOnlyOwnedScheduleList(),
        fetchSharedScheduleList(),
      ]);
    };

    fetchDevicesAndSchedules();
  }, []);

  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        <div className={classes["schedule-builder-wrapper"]}>
          <div className={classes["schedule-builder-container"]}>
            <ScheduleBuilder
              scheduleString={scheduleString}
              setScheduleString={setScheduleString}
            />
          </div>
          <div className={classes["add-schedule-container"]}>
            <AddSchedule
             scheduleString={scheduleString}
             setScheduleList={setOwnedScheduleList}
            />
          </div>
        </div>

        
        <div className={classes["schedules-list-container"]}>
          <div className={classes["own-schedules-list-container"]}>
            <OwnSchedules
              scheduleList={ownedScheduleList}
              setScheduleList={setOwnedScheduleList}
            />
          </div>

          <div className={classes["shared-schedules-list-container"]}>
            <PublicSchedules
              scheduleList={sharedScheduleList}
              setOwnedScheduleList={setOwnedScheduleList}
              setSharedScheduleList={setSharedScheduleList}
            />
          </div>
        </div>

        <div className={classes["aside-for-schedule-builder-container"]}></div>
      </div>
    )
  );
};

export default Scheduling;
