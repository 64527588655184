import React, { useEffect } from "react";
import windowOperations from "../../../helpers/windowOperations";
import { links } from "../../../enums/links";

function Logout() {
  useEffect(() => {
    windowOperations.goToLink(links.logOutLink);
  }, []);

  return <></>;
}

export default Logout;
