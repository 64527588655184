import React, { useEffect } from "react";
import windowOperations from "../../../helpers/windowOperations";
import { links } from "../../../enums/links";

function Login() {  
  useEffect(() => {
    windowOperations.goToLink(links.loginLink);
  }, []);

  return <></>;
}

export default Login;
