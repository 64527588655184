import PhoneRadioAudioMode from "./PhoneRadioAudioMode";
import OffRadioAudioMode from "./OffRadioAudioMode";
import BroadcastRadioAudioMode from "./BroadcastRadioAudioMode";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";

const RadioAudioModePicker = ({ radioAudioMode }) => {
  const renderRadioAudioMode = (radioAudioMode) => {
    const radioAudioModes = {
      0: <OffRadioAudioMode startIcon="end">{devicesListDictionary.offRadioAudioModeText}</OffRadioAudioMode>,
      1: <PhoneRadioAudioMode startIcon="start">{devicesListDictionary.phoneRadioAudioModeText}</PhoneRadioAudioMode>,
      2: <BroadcastRadioAudioMode startIcon="start">{devicesListDictionary.broadcastRadioAudioModeText}</BroadcastRadioAudioMode>
    };

    const component = radioAudioModes[radioAudioMode];
    if (component)
      return component;
    else
      return <>{deviceStatusType.notApplicable}</>;
  };

  return renderRadioAudioMode(radioAudioMode);
};

export default RadioAudioModePicker;
