import classes from "../../../../css/pages/devices-list/profiles/gsm-gate/deviceHeader.module.css";
import Button from "../../../../assets/buttons/Button";
import SortingIcon from "../../../../assets/icons/SortingIcon";
import buttonClasses from "../../../../css/assets/buttons/button.module.css";
import { useState } from "react";
import { devicesListDictionary } from "../../../../../dictionary/ua/devicesListDictionary";

const DeviceHeader = () => {
  const [active, setActive] = useState(null);
  const [pressed, setPressed] = useState(null);
  const setActivityStatus = (id) => (active === id ? "active" : "");
  const setPressedStatus = (id) => { 
    return (pressed === id ? "pressed" : "");
  }

  const handleClick = (event) => {
    // TODO: implement sorting
    setActive(event.currentTarget.id);
  };

  const handleMouseDown = (event) => {
    setPressed(event.currentTarget.id);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = (event) => {
    setPressed(null);
  };

  return (
    <>
      <div className={classes["header"]}>
        <div className={classes["gsm-column"]}>
          <Button
            id="gsm-button"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses[setActivityStatus("gsm-button")]
            } ${buttonClasses[setPressedStatus("gsm-button")]}`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
           {devicesListDictionary.gsmHeaderText}
          </Button>
        </div>
        <div className={classes["di-column"]}>
          <Button
            id="di-button"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses[setActivityStatus("di-button")]
            } ${buttonClasses[setPressedStatus("di-button")]}`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesListDictionary.gsmGateDIHeaderText}
          </Button>
        </div>
        <div className={classes["sorting-by-name-column"]}>
          <Button
            id="sorting-by-name-button"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses[setActivityStatus("sorting-by-name-button")]
            } ${buttonClasses[setPressedStatus("sorting-by-name-button")]}`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesListDictionary.sortingByNameHeaderText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default DeviceHeader;
