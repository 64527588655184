const RadioSilenceIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29998 11.5C5.29998 7.93479 8.2747 5 12 5C15.7253 5 18.7 7.93479 18.7 11.5V12.5714V17.9286V19C18.7 19.5523 19.1477 20 19.7 20H21C21.5486 20 22.0798 19.788 22.4756 19.4025C22.8722 19.0162 23.1 18.4866 23.1 17.9286V13.6429C23.1 13.0849 22.8722 12.5552 22.4756 12.1689C22.0798 11.7834 21.5486 11.5714 21 11.5714H20.7V11.5C20.7 6.78093 16.7799 3 12 3C7.22007 3 3.29998 6.78093 3.29998 11.5V11.5714H2.99996C2.45138 11.5714 1.92013 11.7834 1.5244 12.1689C1.12778 12.5552 0.899963 13.0849 0.899963 13.6429V17.9286C0.899963 18.4866 1.12778 19.0162 1.5244 19.4025C1.92013 19.788 2.45138 20 2.99996 20H4.29998C4.85227 20 5.29998 19.5523 5.29998 19V17.9286V12.5714V11.5ZM14.7782 10.7071C15.1687 10.3166 15.8019 10.3166 16.1924 10.7071C16.5829 11.0976 16.5829 11.7308 16.1924 12.1213L13.3639 14.9498L16.1924 17.7782C16.5829 18.1687 16.5829 18.8019 16.1924 19.1924C15.8019 19.5829 15.1687 19.5829 14.7782 19.1924L11.9497 16.364L9.12133 19.1924C8.73081 19.5829 8.09764 19.5829 7.70712 19.1924C7.31659 18.8019 7.31659 18.1687 7.70712 17.7782L10.5355 14.9498L7.70711 12.1214C7.31658 11.7308 7.31658 11.0977 7.70711 10.7071C8.09763 10.3166 8.7308 10.3166 9.12132 10.7071L11.9497 13.5356L14.7782 10.7071ZM2.70001 13.5C2.70001 13.2239 2.92387 13 3.20001 13C3.47615 13 3.70001 13.2239 3.70001 13.5V18C3.70001 18.2761 3.47615 18.5 3.20001 18.5C2.92387 18.5 2.70001 18.2761 2.70001 18V13.5ZM20.7 13C20.4239 13 20.2 13.2239 20.2 13.5V18C20.2 18.2761 20.4239 18.5 20.7 18.5C20.9762 18.5 21.2 18.2761 21.2 18V13.5C21.2 13.2239 20.9762 13 20.7 13Z"
        fill="white"
      />
    </svg>
  );
};

export default RadioSilenceIcon;
