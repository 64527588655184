import { authApi } from "../../api/authApi";
import { useEffect, useState } from "react";
import { clients } from "../../enums/clients";

const useSetCurrentUserResult = () => {
  const [currentUserResult, setCurrentUserResult] = useState({
    currentUsername: "",
    isAuthenticated: false,
    isRequestCompleted: false,
    roles: []
  });

  const getCurrentUser = async () => {
    try {
      await authApi.getUser().then((response) => {
        let roles = [];
        const clientClaim = response?.claims?.[clients.imitator];
        if(clientClaim) {
          const parsed = JSON.parse(clientClaim);
          roles = parsed?.roles;
        }

        setCurrentUserResult({
          currentUsername: response?.claims?.preferred_username,
          isAuthenticated: response.isAuthenticated,
          isRequestCompleted: true,
          roles: roles
        });
      });
    } catch (error) {
        setCurrentUserResult({
          currentUsername: "",
          isAuthenticated: false,
          isRequestCompleted: true,
          roles: [],
        });
    }
  };
  
  useEffect(() => {  
    getCurrentUser();
  }, []);

  return currentUserResult;
};

export default useSetCurrentUserResult;
