export const devicesListDictionary = {
    radioSilenceInputLabel: "Час радіотиші (хв)",
    gsmAudioModeDropDownLabel: "Режим для GSM",
    radioAudioModeDropDownLabel: "Режим радіо",
    scheduleInputLabel: "Команда",
    radioSilenceButtonText: "Радіо тиша",
    gsmAudioModeButtonText: "Підтвердити",
    radioAudioModeButtonText: "Підтвердити",
    scheduleButtonText: "Надіслати",
    radioSilenceHeaderText: "Радіотиша",
    gsmAudioModeHeaderText: "Gsm статус",
    radioAudioModeHeaderText: "Радіо статус",
    gsmHeaderText: "Gsm",
    batteryVoltageLevelHeaderText: "Батарея",
    scheduleHeaderText: "Статус сценарію",
    relayHeaderText: "Реле",
    sortingByNameHeaderText: "А/Я",
    ehoGsmAudioModeText: "ехо",
    monoGsmAudioModeText: "моно",
    offRadioAudioModeText: "Вимкн.",
    phoneRadioAudioModeText: "телефон",
    broadcastRadioAudioModeText: "етер",
    radioSilenceMinutesText: "хв",
    scheduleMinutesText: "хв",
    scheduleStopText: "Стоп",
    noAvailableDeviceText: "Немає доступних пристроїв",
    lastUpdatedStatusText: "Востаннє оновлено:",
    lastOnlineStatusText: "Востаннє в мережі:",
    zeroMinutesText: "0хв",
    radioSilenceCommandInProgessText: "Команда \"Радіотиша\" вже виконується",
    deviceNotConnectedText: "Пристрій не на зв'язку: ",
    relayDropDownLabel: "Реле",
    relayButtonText: "Підтвердити",
    offRelayText: "Вимкн.",
    onRelayText: "Увімкн.",
    fdoDropDownLabel: "Вихід FDO",    
    fdoHeaderText: "FDO",
    diDropDownLabel: "Вхід DI",    
    diHeaderText: "DI",
    gsmGateDIHeaderText: "Стан DI",
    uaCurrencyText: " грн",
    gsmDIHeaderText: "Стан DI",
    closedDIText: "Увімкн.",
    openDIText: "Вимкн.",
    getRevokedDeviceNote: (revoked) => revoked ? "(Заблоковано. Зверніться до адміністратора)" : ""
}