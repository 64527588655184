import classes from "../../../../css/pages/scheduling/own-schedules/scheduleHeader.module.css";
import Button from "../../../../assets/buttons/Button";
import SortingIcon from "../../../../assets/icons/SortingIcon";
import buttonClasses from "../../../../css/assets/buttons/button.module.css";
import { useState } from "react";
import DeleteItemsDialog from "../../../../assets/dialogs/DeleteItemsDialog";
import { scheduleApi } from "../../../../../api/scheduleApi";
import { scheduleHeaderDictionary } from "../../../../../dictionary/ua/scheduling/scheduleHeaderDictionary";
import { schedulingDictionary } from "../../../../../dictionary/ua/scheduling/schedulingDictionary";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { searchSchedulesSchema } from "../../../../../validation/validationSchemas";
import { FormControl, FormHelperText, InputAdornment, OutlinedInput } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import UListForSchedules from "../../../../assets/lists/UListForSchedules";
import { deleteScheduleDialogDictionary } from "../../../../../dictionary/ua/scheduling/deleteScheduleDialogDictionary";

const ScheduleHeader = ({ scheduleList, setScheduleList }) => {
  const [open, setOpen] = useState(false);
  const [schedulesToDelete, setSchedulesToDelete] = useState([]);
  const [active, setActive] = useState(null);
  const [pressed, setPressed] = useState(null);
  const [search, setSearch] = useState("");
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchSchedulesSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("search").invalid;
  }

  const setActivityStatus = (id) => {
    return active === id ? "active" : "";
  };
  const setPressedStatus = (id) => {
    return pressed === id ? "pressed" : "";
  };

  const handleClick = (event) => {
    setActive(event.currentTarget.id);
  };

  const handleMouseDown = (event) => {
    setPressed(event.currentTarget.id);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(null);
  };

  const handleOpenDeleteDevicesDialog = () => {
    const schedules = scheduleList.filter(
      (schedule) => schedule.toDelete === true
    );

    if (schedules.length > 0) {
      setSchedulesToDelete(schedules);
      setOpen(true);
    }
  };

  const handleCloseDeleteDevicesDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      schedulesToDelete.forEach((schedule) => {
        scheduleApi.delete(schedule.id).then((response) => {
          if (response.success === true) {
            setScheduleList((prevState) => {
              return prevState.filter((sch) => sch.id !== schedule.id);
            });
          }
        });
      });
    }
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    handleSubmit(onSubmit)();
  }

  const onSubmit = (arg) => {
    const encodedSearch = encodeURIComponent(arg.search);
    scheduleApi
      .getOnlyOwnedByUser(encodedSearch)
      .then((response) => {
        setScheduleList(response.data);
      })
      .catch(function (error) {
        //log error
      });
  };

  return (
    <div className={classes["header"]}>
      <div className={classes["title-container"]}>
        <span className={classes["own-schedules-label"]}>
          {schedulingDictionary.ownSchedulesLabelText}
        </span>
      </div>
      <div className={classes["search-container"]}>
        <form className={classes["search-form"]} onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            className={classes["search-input-control"]}
            onChange={handleChange}
            error={isError()}
          >
            <OutlinedInput
              size="small"
              value={search}
              name="search"
              type="text"
              {...register("search")}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <FormHelperText>{errors?.search?.message}</FormHelperText>
          </FormControl>
        </form>
      </div>
      <div className={classes["header-buttons-container"]}>
        <div className={classes["title-header-column"]}>
          <Button
            id="titleButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["schedule-title-header-button"]
            } ${buttonClasses[setActivityStatus("titleButton")]} ${
              buttonClasses[setPressedStatus("titleButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {scheduleHeaderDictionary.title}
          </Button>
        </div>
        <div className={classes["description-header-column"]}>
          <Button
            id="descriptionButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["schedule-description-header-button"]
            } ${buttonClasses[setActivityStatus("descriptionButton")]} ${
              buttonClasses[setPressedStatus("descriptionButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {scheduleHeaderDictionary.description}
          </Button>
        </div>
        <div className={classes["scheduleString-header-column"]}>
          <Button
            id="scheduleStringButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["scheduleString-header-button"]
            } ${buttonClasses[setActivityStatus("scheduleStringButton")]} ${
              buttonClasses[setPressedStatus("scheduleStringButton")]
            }`}
            icon={<SortingIcon />}
            startIcon="end"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {scheduleHeaderDictionary.schedule}
          </Button>
        </div>
        <div className={classes["delete-schedule-header-column"]}>
          <Button
            id="deleteScheduleButton"
            className={`${buttonClasses["column-header-button"]} ${
              buttonClasses["delete-schedule-header-button"]
            } ${buttonClasses[setPressedStatus("deleteScheduleButton")]}`}
            startIcon="end"
            onClick={handleOpenDeleteDevicesDialog}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {scheduleHeaderDictionary.deleteScheduleHeaderText}
          </Button>
          <DeleteItemsDialog
            open={open}
            onClose={handleCloseDeleteDevicesDialog}
            items={schedulesToDelete}
            deleteItemDialogDictionary={deleteScheduleDialogDictionary}
            UList={UListForSchedules}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleHeader;
