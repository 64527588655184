const OneBarGsmSignalLevel = () => {
  return (
    <div>
       <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="12" width="2" height="4" rx="1" fill="#343434" />
        <rect x="4" y="9" width="2" height="7" rx="1" fill="#FFFFFF" />
        <rect x="8" y="6" width="2" height="10" rx="1" fill="#FFFFFF" />
        <rect x="12" y="3" width="2" height="13" rx="1" fill="#FFFFFF" />
        <rect x="16" width="2" height="16" rx="1" fill="#FFFFFF" />
      </svg>
    </div>
  );
};

export default OneBarGsmSignalLevel;
