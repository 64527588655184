import React from "react";
import classes from "../../../../css/pages/scheduling/own-schedules/schedulesList.module.css";
import ScheduleRow from "./ScheduleRow";
import ScheduleHeader from "./ScheduleHeader";

const OwnSchedules = ({ scheduleList, setScheduleList }) => {
  return (
    <div className={classes["own-schedules-wrapper"]}>
      <div className={classes["header-container"]}>
        <div className={classes["schedule-header"]}>
          <ScheduleHeader
            scheduleList={scheduleList}
            setScheduleList={setScheduleList}
          />
        </div>
      </div>
      {scheduleList.length > 0 && (
        <div className={classes["body-container"]}>
          <div className={classes["schedule-row"]}>
            <div className={classes["rows-container"]}>
              {scheduleList.map((schedule) => (
                <ScheduleRow
                  key={schedule.id}
                  schedule={schedule}
                  setScheduleList={setScheduleList}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnSchedules;
