import React, { useEffect, useState } from "react";
import classes from "../../css/pages/scheduling/editSchedule.module.css";
import { useForm } from "react-hook-form";
import { FormControl, FormHelperText, MenuItem, OutlinedInput, Select } from "@mui/material";
import Button from "../../assets/buttons/Button";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import { scheduleApi } from "../../../api/scheduleApi";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import { Link, useParams } from "react-router-dom";
import { editScheduleErrorCode, editScheduleErrorMapper } from "../../../enums/errorMappers/editScheduleErrorMapper";
import { editScheduleDictionary } from "../../../dictionary/ua/scheduling/editScheduleDictionary";
import ScheduleBuilder from "./ScheduleBuilder";
import PrivateScheduleIcon from "../../assets/icons/schedule-accessiblity/PrivateScheduleIcon";
import SharedScheduleIcon from "../../assets/icons/schedule-accessiblity/SharedScheduleIcon";
import ReturnButton from "../../assets/buttons/ReturnButton";
import { links } from "../../../enums/links";

function EditSchedule() {
  useDocumentTitle(documentTitleDictionary.editScheduleTitle + " - " + documentTitleDictionary.mainTitle);
  const { id } = useParams();
  const [apiErrors, setApiErrors] = useState([]);
  const [scheduleString, setScheduleString] = useState('');
  const [pressed, setPressed] = useState(false);
  const [schedule, setSchedule] = useState({
    id: "",
    name: "",
    description: "",
    scheduleString: "",
    shared: false
  });

  const {
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const onSubmit = async () => {
    let payload = {
        id: schedule.id,
        name: schedule.name,
        description: schedule.description,
        scheduleString: scheduleString,
        shared: schedule.shared
      };
      
      scheduleApi.update(payload, links.getSheduleEditLink(id)).then((response) => {
        if (!response.success) {
          setErrors(editScheduleErrorCode.FailedToUpdateSchedule);
        }
      });
  };

  const handleChange = (event) => {
    setSchedule((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value
        };
      });
  };

  const setErrors = (errorCode) => {
    setApiErrors([
      {
        errorCode: errorCode,
        errorText:
          editScheduleErrorMapper[
            errorCode
          ],
      },
    ]);
  }

  const fetchSchedule = async (id) => {
    try {
      const response = await scheduleApi.getById(id, links.getSheduleEditLink(id));
      if (response.success) {
        setSchedule(response.data);
        setScheduleString(response.data.scheduleString);
      } else {
        setErrors(editScheduleErrorCode.FailedToRetrieveSchedule);
      }
    } catch (error) {
      setErrors(editScheduleErrorCode.FailedToRetrieveSchedule);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchSchedule(id);
    };

    fetch();
  }, []);

  return (
    <div className={classes["edit-schedule-wrapper"]}>
      <div className={classes["schedule-builder-container"]}>
        <ScheduleBuilder
           scheduleString={scheduleString}
           setScheduleString={setScheduleString}
        />
      </div>

      <div className={classes["schedule-details-editor-container"]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="name">
                  {editScheduleDictionary.titleLabelText}
                </label>
              </span>
              <OutlinedInput
                id="name"
                type="text"
                name="name"
                size="small"
                value={schedule.name}
              />
              {apiErrors.map((e) => (
                <FormHelperText key={e.errorCode}>{e.errorText}</FormHelperText>
              ))}
            </FormControl>
          </div>

          <div>
            <FormControl
              className={classes["form-control"]}
              onChange={handleChange}
            >
              <span className={classes["label-container"]}>
                <label htmlFor="description">
                  {editScheduleDictionary.descriptionLabelText}
                </label>
              </span>
              <OutlinedInput
                id="description"
                type="text"
                name="description"
                size="small"
                value={schedule.description}
              />
            </FormControl>
          </div>

          <div>
            <FormControl className={classes["form-control"]}>
              <Select
                id="shared"
                name="shared"
                className={classes["shared-select"]}
                value={schedule.shared}
                size="small"
                onChange={handleChange}
              >
                <MenuItem value={false}>
                  <PrivateScheduleIcon startIcon="end">
                    {editScheduleDictionary.privateScheduleMenuItemText}
                  </PrivateScheduleIcon>
                </MenuItem>
                <MenuItem value={true}>
                  <SharedScheduleIcon startIcon="end">
                    {editScheduleDictionary.sharedScheduleMenuItemText}
                  </SharedScheduleIcon>
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <Button
              className={`${buttonClasses["edit-schedule-button"]} ${
                buttonClasses[setPressedClass()]
              }`}
              disabled={false}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {editScheduleDictionary.editScheduleButtonText}
            </Button>
          </div>
        </form>

        <Link className={classes["return-to-scheduling-link"]} to={links.schedulingPath}>
          <ReturnButton>{editScheduleDictionary.returnToScheduling}</ReturnButton>
        </Link>
      </div>
    </div>
  );
}

export default EditSchedule;
