export const links = {
    deviceListLink: "device-list",
    devicesLink: "devices",
    getLoginLink: (redirect) => `${process.env?.REACT_APP_API_BASE_URL}/auth/login?redirectTo=/${redirect}`,
    loginLink: `${process.env?.REACT_APP_API_BASE_URL}/auth/login?redirectTo=/devices`,
    logOutLink: `${process.env?.REACT_APP_API_BASE_URL}/auth/logout?redirectTo=/devices`,    
    schedulingLink: "scheduling",
    addDevicePath: "/add-device",
    deviceListPath: "/device-list",
    devicesPath: "/devices",
    loginPath: "/login",
    logOutPath: "/logout",
    schedulingPath: "/scheduling",
    getSheduleEditLink: (scheduleId) => `../schedule/${scheduleId}`,
    scheduleEditPath: "/schedule",
    getDeviceEditLink: (deviceId) => `../device/${deviceId}`,
    deviceEditPath: "/device",
    devicesManagementLink: "devices-management",
    devicesManagementPath: "/devices-management",
    getCertificatesManagementByDeviceEditLink: (deviceId) => `../certificates-management/${deviceId}`,
    certificatesManagementPath: "/certificates-management",
    certificatesManagementLink: "certificates-management",
    signalRUrl: `${process.env?.REACT_APP_API_BASE_URL}/api/hub`,
    deviceMessagesManagementPath: "/device-messages-management",
    deviceMessagesManagementLink: "device-messages-management",
    getDeviceMessagesManagementByDeviceEditLink: (deviceId) => `../device-messages-management/${deviceId}`,
    getUserMessagesManagementByUsernameEditLink: (username) => `../user-messages-management/${username}`,
    userMessagesManagementPath: "/user-messages-management",
    userMessagesManagementLink: "user-messages-management",
    usersManagementPath: "/users-management",
    usersManagementLink: "users-management",
    userMessagesPath: "/user-messages",
    userMessagesLink: "user-messages",
    firmwaremanagementPath: "/firmware-management",
    firmwareManagementLink: "firmware-management",
  };
  