import { deviceStatusType } from "../../../enums/deviceStatus";
import { devicesListDictionary } from "../../../dictionary/ua/devicesListDictionary";
import OffRelay from "./OffRelay";
import OnRelay from "./OnRelay";

const RelayPicker = ({ relayMode }) => {
  const renderRelayMode = (relayMode) => {
    const relayModes = {
      0: <OffRelay>{devicesListDictionary.offRelayText}</OffRelay>,
      1: <OnRelay>{devicesListDictionary.onRelayText}</OnRelay>
    };

    const component = relayModes[relayMode];
    if (component)
      return component;
    else
      return <>{deviceStatusType.notApplicable}</>;
  };

  return renderRelayMode(relayMode);
};

export default RelayPicker;
