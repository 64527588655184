import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const deviceMessagesApi = {
  create: async function (deviceMessage, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/deviceMessages`,
          method: "POST",
          data: deviceMessage,
        }),
      redirect
    );
  },
  update: async function (deviceMessage, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/deviceMessages`,
          method: "PUT",
          data: deviceMessage,
        }),
      redirect
    );
  },
  getByDeviceId: async function (deviceId, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/deviceMessages/management/device/${deviceId}`,
          method: "GET",
        }),
      redirect
    );
  },
  delete: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/deviceMessages/${id}`,
          method: "DELETE",
        }),
      redirect
    );
  },
};
