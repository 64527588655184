import React, { useState } from "react";
import classes from "../../../../css/pages/devices-list/profiles/remote-switch/controlPanel.module.css";
import RelayCommand from "../../../../commands/RelayCommand";

const ControlPanel = ({ profile, selectedDevicesClientIds, setDeviceList, selectAllDevicesCheckboxIndicator }) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllDevicesClick = () => {
    setDeviceList((prevState) => {
      const updatedGrouped = { ...prevState };
      updatedGrouped[profile] = {
        array: prevState[profile].array.map((device) => ({
          ...device,
          selectedForSendingMessage: !selectAll,
        })),
      };
    
      return updatedGrouped;
    });

    setSelectAll(!selectAll);
  };
  return (
    <div className={classes["control-panel-wrapper"]}>
      <div className={classes["commands-row-wrapper"]}>
        <div className={classes["commands-row"]}>
          <RelayCommand selectedDevicesClientIds={selectedDevicesClientIds} />
        </div>
      </div>

      <div className={classes["select-all-devices-checkbox-wrapper"]}>
        <label htmlFor="select-all-devices-checkbox">
          Вибрати всі пристрої
        </label>
        <input
          id="select-all-checkbox"
          type="checkbox"
          className={classes["select-all-devices-checkbox"]}
          onChange={handleSelectAllDevicesClick}
          checked={selectAllDevicesCheckboxIndicator[profile]}
        />
      </div>
    </div>
  );
}

export default ControlPanel;