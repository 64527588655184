import { devicesListDictionary } from "../../../../dictionary/ua/devicesListDictionary";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import Closed from "./Closed";
import Open from "./Open";

const DIStatusPicker = ({ diStatus }) => {
  const renderDIStatus = (diStatus) => {
    const diStatuses = {
      "closed": <Closed>{devicesListDictionary.closedDIText}</Closed>,
      "open": <Open>{devicesListDictionary.openDIText}</Open>
    };

    const component = diStatuses[diStatus];
    if (component) return component;
    else return <>{deviceStatusType.notApplicable}</>;
  };

  return renderDIStatus(diStatus);
};

export default DIStatusPicker;
