import classes from "../../../css/assets/icons/radioAudioMode/broadcastRadioAudioMode.module.css";

const BroadcastRadioAudioMode = ({children, startIcon}) => {
  return (
    <div className={classes.wrapper}>
       <span className={classes[startIcon]}>
        <svg
          width="24"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4.85783C11.1903 4.85663 10.4063 5.14202 9.78689 5.66346C9.16746 6.1849 8.75255 6.90874 8.61567 7.70677C8.47879 8.50481 8.62875 9.32554 9.03901 10.0236C9.44926 10.7217 10.0933 11.252 10.8571 11.5207C10.8516 11.5853 10.8516 11.6503 10.8571 11.715V18.5721C10.8571 18.8752 10.9776 19.1659 11.1919 19.3802C11.4062 19.5946 11.6969 19.715 12 19.715C12.3031 19.715 12.5938 19.5946 12.8081 19.3802C13.0224 19.1659 13.1429 18.8752 13.1429 18.5721V11.715C13.1484 11.6503 13.1484 11.5853 13.1429 11.5207C13.9067 11.252 14.5507 10.7217 14.961 10.0236C15.3712 9.32554 15.5212 8.50481 15.3843 7.70677C15.2474 6.90874 14.8325 6.1849 14.2131 5.66346C13.5937 5.14202 12.8097 4.85663 12 4.85783ZM2.28571 8.2864C2.31693 7.12931 2.6036 5.99343 3.12521 4.9601C3.64681 3.92676 4.39045 3.02155 5.30286 2.30926C5.53625 2.11527 5.68302 1.83652 5.71088 1.53432C5.73874 1.23212 5.64542 0.931224 5.45143 0.697833C5.35538 0.58227 5.2375 0.486757 5.10454 0.416748C4.97157 0.346739 4.82612 0.303605 4.67648 0.289809C4.37428 0.261947 4.07339 0.355274 3.84 0.549261C2.66781 1.47488 1.71544 2.64889 1.05144 3.98676C0.387444 5.32463 0.0283459 6.79309 0 8.2864C0.0283459 9.77972 0.387444 11.2482 1.05144 12.586C1.71544 13.9239 2.66781 15.0979 3.84 16.0235C3.95556 16.1196 4.08891 16.192 4.23244 16.2365C4.37596 16.281 4.52685 16.2968 4.67648 16.283C4.82612 16.2692 4.97157 16.2261 5.10454 16.1561C5.2375 16.0861 5.35538 15.9905 5.45143 15.875C5.54748 15.7594 5.61983 15.6261 5.66435 15.4825C5.70886 15.339 5.72468 15.1881 5.71088 15.0385C5.69708 14.8889 5.65395 14.7434 5.58394 14.6104C5.51393 14.4775 5.41842 14.3596 5.30286 14.2635C4.39045 13.5513 3.64681 12.646 3.12521 11.6127C2.6036 10.5794 2.31693 9.4435 2.28571 8.2864Z"
            fill="#343434"
          />
          <path
            d="M17.3029 2.84638C17.0691 2.69101 16.7863 2.62713 16.5084 2.66691C16.2305 2.7067 15.977 2.84736 15.7961 3.06208C15.6153 3.2768 15.5199 3.55057 15.5279 3.83116C15.536 4.11176 15.6471 4.37957 15.84 4.58352C16.4048 5.02558 16.8654 5.58642 17.1893 6.22632C17.5132 6.86621 17.6924 7.56951 17.7143 8.28638C17.6924 9.00325 17.5132 9.70655 17.1893 10.3464C16.8654 10.9863 16.4048 11.5472 15.84 11.9892C15.6632 12.1404 15.5367 12.342 15.4775 12.567C15.4183 12.792 15.4291 13.0298 15.5087 13.2484C15.5882 13.4671 15.7325 13.6563 15.9224 13.7907C16.1124 13.9251 16.3388 13.9984 16.5714 14.0007C16.8399 13.998 17.0988 13.9009 17.3029 13.7264C18.1274 13.0761 18.7972 12.2508 19.2637 11.3099C19.7301 10.3691 19.9816 9.33634 20 8.28638C19.9816 7.23642 19.7301 6.20367 19.2637 5.26284C18.7972 4.32201 18.1274 3.49662 17.3029 2.84638ZM8.29714 2.98352C8.10207 2.75486 7.82476 2.61224 7.52529 2.58658C7.22582 2.56091 6.92829 2.65425 6.69714 2.84638C5.87256 3.49662 5.2028 4.32201 4.73634 5.26284C4.26988 6.20367 4.01837 7.23642 4 8.28638C4.01837 9.33634 4.26988 10.3691 4.73634 11.3099C5.2028 12.2508 5.87256 13.0761 6.69714 13.7264C6.92829 13.9185 7.22582 14.0119 7.52529 13.9862C7.82476 13.9605 8.10207 13.8179 8.29714 13.5892C8.48928 13.3581 8.58262 13.0606 8.55695 12.7611C8.53128 12.4616 8.38867 12.1843 8.16 11.9892C7.59523 11.5472 7.13457 10.9863 6.81068 10.3464C6.48678 9.70655 6.30756 9.00325 6.28571 8.28638C6.30756 7.56951 6.48678 6.86621 6.81068 6.22632C7.13457 5.58642 7.59523 5.02558 8.16 4.58352C8.38867 4.38845 8.53128 4.11114 8.55695 3.81167C8.58262 3.5122 8.48928 3.21467 8.29714 2.98352Z"
            fill="#343434"
          />
          <path
            d="M20.16 0.549258C20.0445 0.453206 19.9111 0.380856 19.7676 0.336339C19.6241 0.291822 19.4732 0.27601 19.3236 0.289806C19.1739 0.303602 19.0285 0.346736 18.8955 0.416745C18.7625 0.486754 18.6447 0.582267 18.5486 0.69783C18.4526 0.813393 18.3802 0.946744 18.3357 1.09027C18.2912 1.23379 18.2754 1.38468 18.2892 1.53431C18.303 1.68395 18.3461 1.8294 18.4161 1.96237C18.4861 2.09533 18.5816 2.21321 18.6972 2.30926C19.6096 3.02154 20.3532 3.92676 20.8748 4.96009C21.3964 5.99342 21.6831 7.12931 21.7143 8.2864C21.6831 9.4435 21.3964 10.5794 20.8748 11.6127C20.3532 12.646 19.6096 13.5513 18.6972 14.2635C18.4638 14.4575 18.317 14.7363 18.2892 15.0385C18.2613 15.3407 18.3546 15.6416 18.5486 15.875C18.7426 16.1084 19.0214 16.2551 19.3236 16.283C19.6258 16.3109 19.9267 16.2175 20.16 16.0235C21.3322 15.0979 22.2846 13.9239 22.9486 12.586C23.6126 11.2482 23.9717 9.77972 24 8.2864C23.9717 6.79309 23.6126 5.32463 22.9486 3.98676C22.2846 2.64888 21.3322 1.47488 20.16 0.549258Z"
            fill="#343434"
          />
        </svg>
      </span>

      <span className={classes.text}>{children}</span>
    </div>
  );
};

export default BroadcastRadioAudioMode;
