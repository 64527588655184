import { deviceStatusType } from "../enums/deviceStatus";

const deviceMapper = {
  mapToExtendedDeviceStateObject: (device) => ({
    ...device,
    batteryVoltageLevelStatus: deviceStatusType.notApplicable,
    batteryVoltageLevelStatusLastUpdated: deviceStatusType.notApplicable,
    gsm1SignalLevelStatus: deviceStatusType.notApplicable,
    gsm1SignalLevelStatusLastUpdated: deviceStatusType.notApplicable,
    gsm2SignalLevelStatus: deviceStatusType.notApplicable,
    gsm2SignalLevelStatusLastUpdated: deviceStatusType.notApplicable,
    gsm3SignalLevelStatus: deviceStatusType.notApplicable,
    gsm3SignalLevelStatusLastUpdated: deviceStatusType.notApplicable,
    gsmAudioModeStatus: deviceStatusType.notApplicable,
    gsmAudioModeStatusLastUpdated: deviceStatusType.notApplicable,
    radioAudioModeStatus: deviceStatusType.notApplicable,
    radioAudioModeStatusLastUpdated: deviceStatusType.notApplicable,
    radioSilenceStatus: deviceStatusType.notApplicable,
    radioSilenceStatusLastUpdated: deviceStatusType.notApplicable,
    scheduleStatus: deviceStatusType.notApplicable,
    scheduleStatusLastUpdated: deviceStatusType.notApplicable,
    selectedForSendingMessage: false,
    gsm1BalanceStatus: deviceStatusType.notApplicable,
    gsm1BalanceStatusLastUpdated: deviceStatusType.notApplicable,
    gsm2BalanceStatus: deviceStatusType.notApplicable,
    gsm2BalanceStatusLastUpdated: deviceStatusType.notApplicable,
    gsm3BalanceStatus: deviceStatusType.notApplicable,
    gsm3BalanceStatusLastUpdated: deviceStatusType.notApplicable,
  }),
  mapToDeletableObject: (device) => ({
    ...device,
    toDelete: false,
  })
};

export default deviceMapper;