import { useState } from "react";
import classes from "../../../../css/pages/scheduling/own-schedules/scheduleRow.module.css";
import DeleteButton from "../../../../assets/buttons/DeleteButton";
import DeleteItemsDialog from "../../../../assets/dialogs/DeleteItemsDialog";
import { scheduleApi } from "../../../../../api/scheduleApi";
import { Link } from "react-router-dom";
import { links } from "../../../../../enums/links";
import EditScheduleButton from "../../../../assets/buttons/EditScheduleButton";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Tooltip } from "@mui/material";
import { schedulingDictionary } from "../../../../../dictionary/ua/scheduling/schedulingDictionary";
import UListForSchedules from "../../../../assets/lists/UListForSchedules";
import { deleteScheduleDialogDictionary } from "../../../../../dictionary/ua/scheduling/deleteScheduleDialogDictionary";

const ScheduleRow = ({ schedule, setScheduleList }) => {
  const [open, setOpen] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(false);
  const setSelectedToDeleteStatus = () => {
    return selectedToDelete === true ? "selected-to-delete" : "";
  };

  const handleChange = () => {
    setSelectedToDelete((prev) => !prev);
    setScheduleList((prevState) => {
      return prevState.map((sch) => {
        if (sch.id === schedule.id) {
          return {
            ...sch,
            toDelete: !sch.toDelete,
          };
        }
        return sch;
      });
    });
  };

  const handleOpenDeleteScheduleDialog = () => {
    setOpen(true);
  };

  const handleCloseDeleteScheduleDialog = (selectedToDelete) => {
    setOpen(false);
    if (selectedToDelete === true) {
      scheduleApi.delete(schedule.id).then((response) => {
        if (response.success === true) {
            setScheduleList((prevState) => {
            return prevState.filter((sch) => schedule.id !== sch.id);
          });
        }
      });
    }
  };

  return (
    <div className={`${classes["row-wrapper"]}`}>
      <div className={`${classes["wrapped-area"]}`}>
        <div
          className={`${classes["row"]} ${
            classes[setSelectedToDeleteStatus()]
          }`}
        >          
          <div className={classes["title-column"]}>{schedule.name}</div>
          <div className={classes["description-column"]}>
            {schedule.description}
          </div>
          <div className={classes["scheduleString-column"]}>
            {schedule.scheduleString}
          </div>
          <div className={classes["sharing-status-column"]}>
            {schedule.shared === true ? (
              <Tooltip
                title={schedulingDictionary.publicScheduleTooltipText}
                arrow
                placement="top"
              >
                <LockOpenIcon />
              </Tooltip>
            ) : (
              <Tooltip
                title={schedulingDictionary.privateScheduleTooltipText}
                arrow
                placement="top"
              >
                <LockIcon />
              </Tooltip>
            )}
          </div>

          <div className={classes["select-schedule-column"]}>
            <Tooltip
              title={schedulingDictionary.selectScheduleTooltipText}
              arrow
              placement="top"
            >
              <span>
                <input
                  type="checkbox"
                  className={classes["select-schedule-checkbox"]}
                  onChange={handleChange}
                />
              </span>
            </Tooltip>
          </div>

          <div className={classes["delete-schedule-column"]}>
            <Tooltip
              title={schedulingDictionary.deleteScheduleTooltipText}
              arrow
              placement="top"
            >
              <span>
                <DeleteButton
                  onClick={handleOpenDeleteScheduleDialog}
                />
                <DeleteItemsDialog
                  open={open}
                  onClose={handleCloseDeleteScheduleDialog}
                  items={[schedule]}
                  deleteItemDialogDictionary={deleteScheduleDialogDictionary}
                  UList={UListForSchedules}
                />
              </span>
            </Tooltip>
          </div>

          <div className={classes["edit-schedule-column"]}>
            <Tooltip
              title={schedulingDictionary.editScheduleTooltipText}
              arrow
              placement="top"
            >
              <Link key="edit" to={links.getSheduleEditLink(schedule.id)}>
                <EditScheduleButton />
              </Link>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleRow;
