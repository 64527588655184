import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { certificatesApi } from "../../../../api/certificatesApi";
import { useState } from "react";
import { certificatesManagementDictionary } from "../../../../dictionary/ua/certificatesManagementDictionary";
import { links } from "../../../../enums/links";

const RevokeCertificateButtonWrapper = ({
  certificate,
  setCertificatesList
}) => {
  const [pressed, setPressed] = useState(false);
  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const apiRevokingStrategies = {
    revoke: () => {
      certificatesApi
        .revoke(
          {
            deviceId: certificate.deviceId,
            serialNumber: certificate.serialNumber
          },
          links.getCertificatesManagementByDeviceEditLink(certificate.deviceId)
        )
        .then((response) => {
          if (response.success) {
            updateCertificatesList(true);
          }
        });
    },
    undoRevocation: () => {
      certificatesApi
        .undoRevocation(
          {
            deviceId: certificate.deviceId,
            serialNumber: certificate.serialNumber
          },
          links.getCertificatesManagementByDeviceEditLink(certificate.deviceId)
        )
        .then((response) => {
          if (response.success) {
            updateCertificatesList(false);
          }
        });
    },
  };

  const updateCertificatesList = (revoked) => {
    setCertificatesList((prevState) => {
      const updatedList = prevState.map((cert) => {
        if (cert.serialNumber === certificate.serialNumber) {
          return {
            ...cert,
            revoked: revoked,
          };
        }

        return cert;
      });

      return updatedList;
    });
  };

  const handleClick = () => {
    const apiRevokingStrategy = !certificate.revoked
      ? apiRevokingStrategies.revoke
      : apiRevokingStrategies.undoRevocation;

    apiRevokingStrategy();
  };

  
  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const getClassname = () =>
    !certificate.revoked
      ? `${buttonClasses["revoke-certificate-button"]} ${
          buttonClasses[setPressedClass()]
        }`
      : `${buttonClasses["undo-revocation-button"]} ${
          buttonClasses[setPressedClass()]
        }`;

  return (
    certificate && (
      <Button
        className={getClassname()}
        startIcon="start"
        onClick={() => handleClick()}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {!certificate.revoked
          ? certificatesManagementDictionary.revokeCertificateButtonLabel
          : certificatesManagementDictionary.undoRevocationButtonLabel}
      </Button>
    )
  );
};

export default RevokeCertificateButtonWrapper;
