import { axiosClient } from "../axios/axiosConfig";

export const authApi = {
  login: async function (loginData) {
    const response = await axiosClient.request({
      url: `/auth/login`,
      method: "POST",
      data: loginData,
    });

    return response.data;
  },
  getUser: async function () {
    const response = await axiosClient.request({
      url: `/auth/user`,
      method: "GET"
    });

    return response.data;
  },
  keepAlive: async function() {
    const response = await axiosClient.request({
      url: `/auth/keep-alive`,
      method: "GET"
    });

    return response.data;
  },
  logout: async function() {
    const response = await axiosClient.request({
      url: `/auth/logout?redirectTo=/`,
      method: "GET"
    });

    return response.data;
  }
};
