import { useEffect, useState } from "react";
import classes from "../../../css/pages/admin/user-management/userManagement.module.css";
import userHeaderClasses from "../../../css/pages/admin/user-management/userHeader.module.css";
import { documentTitleDictionary } from "../../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import useSetCurrentUserResult from "../../../hooks/useSetCurrentUserResult";
import { links } from "../../../../enums/links";
import SortableHeader from "../../../assets/SortableHeader";
import usersManagementDictionaryMapper from "../../../dictionaryMapper/usersManagementDictionaryMapper";
import AddFirmware from "./AddFirmware";
import FirmwareRow from "./FirmwareRow";
import { firmwareManagementDictionary } from "../../../../dictionary/ua/firmwareManagementDictionary";
import { firmwareApi } from "../../../../api/firmwareApi";

const FirmwareManagement = () => {
  useDocumentTitle(documentTitleDictionary.firmwareManagementTitle + " - " + documentTitleDictionary.mainTitle);
  const currentUserResult = useSetCurrentUserResult();
  const [filteredFirmwareList, setFilteredFirmwareList] = useState([]);
  const [firmwareList, setFirmwareList] = useState([]);
  const firmwareFields = [
    { key: 'hardwareVersion', label: firmwareManagementDictionary.hardwareVersionHeaderText },
    { key: 'firmwareVersion', label: firmwareManagementDictionary.firmwareVersionHeaderText },
    { key: 'clientId', label: firmwareManagementDictionary.clientIdHeaderText },
    { key: 'espIdf', label: firmwareManagementDictionary.espIdfHeaderText },
    { key: 'target', label: firmwareManagementDictionary.targetHeaderText },
    { key: 'prjName', label: firmwareManagementDictionary.prjNameHeaderText },
    { key: 'compileDate', label: firmwareManagementDictionary.compileDateHeaderText },
    { key: 'fileLink', label: firmwareManagementDictionary.fileLinkHeaderText },
    { key: 'description', label: firmwareManagementDictionary.descriptionHeaderText },
    { key: 'deviceProfile', label: firmwareManagementDictionary.deviceProfileHeaderText },
    { key: 'ownerUsername', label: firmwareManagementDictionary.ownerUsernameHeaderText },
    { key: 'createdDateTimeUtc', label: firmwareManagementDictionary.createDateTimeHeaderText },
  ];

  useEffect(() => {
    const fetchFirmwareAsync = async () => {
      try {
        const response = await firmwareApi.get(
          links.firmwareManagementLink
        );

        if (response.success && response.data.length > 0) {
          setFirmwareList(response.data);
        }
      } catch (error) {
        console.error("Error fetching firmware list:", error);
        return [];
      }
    };

    fetchFirmwareAsync();
  }, []);
  
  return (
    currentUserResult.isAuthenticated && (
      <div className={classes["main-container"]}>
        <div className={classes["add-firmware-container"]}>
          <AddFirmware
            setFirmwareList={setFirmwareList}
          />
        </div>
        {firmwareList.length > 0 ? (
          <>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["firmware-header"]}>
                <SortableHeader
                  fields={firmwareFields}
                  list={firmwareList}
                  setFilteredList={setFilteredFirmwareList}
                  classes={userHeaderClasses}
                  dictionaryMapper={usersManagementDictionaryMapper}
                />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["firmware-row"]}>
                <div className={classes["rows-container"]}>
                  {filteredFirmwareList.map((firmware) => (
                    <FirmwareRow firmware={firmware} setFirmwareList={setFirmwareList} />
                  ))}
                </div>
              </div>
              <div className={classes["aside-for-body"]}></div>
            </div>
          </>
        ) : (
          <h2>{firmwareManagementDictionary.noFirmwareFound}</h2>
        )}
      </div>
    )
  );
};

export default FirmwareManagement;
