import classes from "../../css/assets/icons/navigationLogo.module.css";
import navigationLogo from "../icons/svg/navigationLogo.svg";
import navigationLogoWithGear from "../icons/svg/navigationLogoWithGear.svg";

const NavigationLogoSelector = () => {
  const environment = process.env.REACT_APP_ENV;
  const logoSrc =
    environment === "development" || environment === "test"
      ? navigationLogoWithGear
      : navigationLogo;

  return (
    <div className={classes["navigation-logo"]}>
      <img src={logoSrc} alt="navigation logo" />
    </div>
  );
};

export default NavigationLogoSelector;
