export const userMessagesDictionary = {
    usernameHeaderText: "Ім'я користувача",
    createdDateTimeUtcHeaderText: "Дата створення",
    messageHeaderText: "Повідомлення",
    messageTypeHeaderText: "Тип повідомлення",
    noAvailableUserMessagesText: "Немає повідомлень для користувача",
    messageInputLabel: "Повідомлення",
    addUserMessageButtonText: "Додати",
    returnToUserMessagesManagementText: "Повернутись до сторінки управління повідомленнями для користувачів",
    returnToUsersManagementText: "Повернутись до сторінки з користувачами",
    messageLabel: "Повідомлення",
    createdDateLabel: "Дата створення"
}